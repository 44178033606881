<template>
    <div :key="$i18n.locale" class="yellow-skin">
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->
            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item active" aria-current="page">{{
                                        $t('property_submission.advertise_your_property') }}</li>
                                </ol>
                                <h2 class="breadcrumb-title">{{ $t('property_submission.submit_your_property') }}</h2>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->

            <!-- ================= Property Submission Form ================= -->
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">

                            <!-- Single Basics List -->
                            <div class="faq_wrap">
                                <div class="faq_wrap_title">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-10 col-md-12">
                                            <div class="sec-heading center">
                                                <h2>{{ $t('property_submission.property_listing_countries') }}</h2>
                                                <p style="color: black;">{{
                                                    $t('property_submission.property_countries_text') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="custom-tab style-1" style="margin-left: 10px;">
                                                <ul class="nav nav-tabs pb-2 b-0" id="myTab" role="tablist">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" id="Greece-tab" data-bs-toggle="tab"
                                                            href="#Greece" role="tab" aria-controls="Greece"
                                                            aria-selected="true">{{
                                                                $t('property_submission.greece') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Malaysia-tab" data-bs-toggle="tab"
                                                            href="#malaysia" role="tab" aria-controls="malaysia"
                                                            aria-selected="false">{{
                                                                $t('property_submission.malaysia') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Singapore-tab" data-bs-toggle="tab"
                                                            href="#singapore" role="tab" aria-controls="singapore"
                                                            aria-selected="false">{{
                                                                $t('property_submission.singapore') }}</a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="myTabContent">
                                                    <!-- Greece Tab -->
                                                    <div class="tab-pane fade show active" id="Greece" role="tabpanel"
                                                        aria-labelledby="Greece-tab">
                                                        <!-- ============================ Submit Property Start ================================== -->
                                                        <section>
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="alert alert-danger" role="alert">
                                                                            <p class="m-0">If you don't have an account
                                                                                you can create one by <a href="#">Click
                                                                                    Here</a>
                                                                            </p>
                                                                        </div>

                                                                    </div>

                                                                    <!-- Submit Form -->
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="submit-page p-0">

                                                                            <!-- Greece Basic Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Greece Property Basic Information
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Property Title<a
                                                                                                    href="#"
                                                                                                    class="tip-topdata"
                                                                                                    data-tip="Property Title"><i
                                                                                                        class="ti-help"></i></a></label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="propertyTitle">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Status</label>
                                                                                            <select id="status"
                                                                                                class="form-control"
                                                                                                v-model="propertyStatus">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">For
                                                                                                    Rent</option>
                                                                                                <option value="2">For
                                                                                                    Sale</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Property Type</label>
                                                                                            <select id="ptypes"
                                                                                                class="form-control"
                                                                                                v-model="propertyType">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    Condominium</option>
                                                                                                <option value="2">
                                                                                                    Apartment</option>
                                                                                                <option value="3">Landed
                                                                                                    House</option>
                                                                                                <option value="4">
                                                                                                    Commercial</option>
                                                                                                <option value="5">
                                                                                                    Offices</option>
                                                                                                <option value="6">Land
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Property
                                                                                                Category</label>
                                                                                            <select id="pcategory"
                                                                                                class="form-control"
                                                                                                v-model="propertyCategory">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">New
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    Second-hand</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Surface Area</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="m²"
                                                                                                v-model="surfaceArea">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Plot Surface
                                                                                                Area</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="m²"
                                                                                                v-model="plotSurfaceArea">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Price</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="Euro"
                                                                                                v-model="price">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Price per m²</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="Euro"
                                                                                                v-model="pricePerSqM">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Bedrooms</label>
                                                                                            <select id="bedrooms"
                                                                                                class="form-control"
                                                                                                v-model="bedrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Bathrooms</label>
                                                                                            <select id="bathrooms"
                                                                                                class="form-control"
                                                                                                v-model="bathrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                           <!-- Gallery -->
                                                                           <div class="frm_submit_block">
                                                                                <h3>Gallery</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Upload
                                                                                                Gallery</label>
                                                                                            <form
                                                                                                action="https://shreethemes.net/upload-target"
                                                                                                class="dropzone dz-clickable primary-dropzone">
                                                                                                <div class="card">
                                                                                                    <div class="drag-area"
                                                                                                        @dragover.prevent="onDragOver"
                                                                                                        @dragleave.prevent="onDragLeave"
                                                                                                        @drop.prevent="onDrop">
                                                                                                        <span
                                                                                                            v-if="!isDragging">
                                                                                                            Drag & drop
                                                                                                            image here
                                                                                                            or
                                                                                                            <span
                                                                                                                class="select"
                                                                                                                role="button"
                                                                                                                @click="selectFiles">
                                                                                                                Choose
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <div v-else
                                                                                                            class="select">
                                                                                                            Drop images
                                                                                                            here</div>
                                                                                                        <input
                                                                                                            name="file"
                                                                                                            type="file"
                                                                                                            class="file"
                                                                                                            ref="fileInput"
                                                                                                            multiple
                                                                                                            @change="onFileSelect" />
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="container">
                                                                                                        <div class="image"
                                                                                                            v-for="(image, index) in images"
                                                                                                            :key="index">
                                                                                                            <span
                                                                                                                class="delete"
                                                                                                                @click="deleteImage(index)">&times;</span>
                                                                                                            <img
                                                                                                                :src="image.url" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Property Video -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Property Video</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Youtube Embed
                                                                                                link</label>
                                                                                            <input type="text"
                                                                                                class="form-control">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Location -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Location in Greece</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Address</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="address">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>City</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="city">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>State</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="state">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Zip Code</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="zipCode">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Greece Property Detailed Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Greece Property Detailed Information
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Description</label>
                                                                                            <textarea
                                                                                                class="form-control h-120"
                                                                                                v-model="description"></textarea>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Building Age
                                                                                                (optional)</label>
                                                                                            <select id="bage"
                                                                                                class="form-control"
                                                                                                v-model="buildingAge">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">0 - 5
                                                                                                    Years</option>
                                                                                                <option value="2">0 - 10
                                                                                                    Years</option>
                                                                                                <option value="3">0 - 15
                                                                                                    Years</option>
                                                                                                <option value="4">0 - 20
                                                                                                    Years</option>
                                                                                                <option value="5">20+
                                                                                                    Years</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Garage
                                                                                                (optional)</label>
                                                                                            <select id="garage"
                                                                                                class="form-control"
                                                                                                v-model="garage">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Rooms
                                                                                                (optional)</label>
                                                                                            <select id="rooms"
                                                                                                class="form-control"
                                                                                                v-model="rooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Heating System
                                                                                                (optional)</label>
                                                                                            <select id="heatingSystem"
                                                                                                class="form-control"
                                                                                                v-model="heatingSystem">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    Central (Petrol)
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    Electric Heating
                                                                                                </option>
                                                                                                <option value="3">Heat
                                                                                                    Pumps</option>
                                                                                                <option value="4">Not
                                                                                                    Available</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Energy class
                                                                                                (optional)</label>
                                                                                            <select id="energyClass"
                                                                                                class="form-control"
                                                                                                v-model="energyClass">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">227% -
                                                                                                    273%</option>
                                                                                                <option value="2">> 273%
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Completion Year
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="completionYear">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Internal Features
                                                                                                (optional)</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.fullFurnished">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">Full-furnished</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.partialFurnished">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">Partial-furnished</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.noFurnished">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">No
                                                                                                            furnished</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.elevator">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">Elevator</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.internalStaircase">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">Internal
                                                                                                            Staircase</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.nightPower">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">Night
                                                                                                            Power</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-7"
                                                                                                            class="form-check-input"
                                                                                                            name="a-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.secureDoor">
                                                                                                        <label for="a-7"
                                                                                                            class="form-check-label">Secure
                                                                                                            door</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-8"
                                                                                                            class="form-check-input"
                                                                                                            name="a-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.doubleGlass">
                                                                                                        <label for="a-8"
                                                                                                            class="form-check-label">Double
                                                                                                            glass</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-9"
                                                                                                            class="form-check-input"
                                                                                                            name="a-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.painted">
                                                                                                        <label for="a-9"
                                                                                                            class="form-check-label">Painted</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-10"
                                                                                                            class="form-check-input"
                                                                                                            name="a-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.wifi">
                                                                                                        <label
                                                                                                            for="a-10"
                                                                                                            class="form-check-label">Wi-Fi</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-11"
                                                                                                            class="form-check-input"
                                                                                                            name="a-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.fireplace">
                                                                                                        <label
                                                                                                            for="a-11"
                                                                                                            class="form-check-label">Fireplace</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-12"
                                                                                                            class="form-check-input"
                                                                                                            name="a-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.woodFloor">
                                                                                                        <label
                                                                                                            for="a-12"
                                                                                                            class="form-check-label">Wood
                                                                                                            Floor
                                                                                                            Type</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-13"
                                                                                                            class="form-check-input"
                                                                                                            name="a-13"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.alarm">
                                                                                                        <label
                                                                                                            for="a-13"
                                                                                                            class="form-check-label">Alarm</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-14"
                                                                                                            class="form-check-input"
                                                                                                            name="a-14"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.windowScreens">
                                                                                                        <label
                                                                                                            for="a-14"
                                                                                                            class="form-check-label">Window
                                                                                                            Screens</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-15"
                                                                                                            class="form-check-input"
                                                                                                            name="a-15"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.airConditioning">
                                                                                                        <label
                                                                                                            for="a-15"
                                                                                                            class="form-check-label">Air
                                                                                                            Conditioning</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-16"
                                                                                                            class="form-check-input"
                                                                                                            name="a-16"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.petsWelcome">
                                                                                                        <label
                                                                                                            for="a-16"
                                                                                                            class="form-check-label">Pets
                                                                                                            Welcome</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-17"
                                                                                                            class="form-check-input"
                                                                                                            name="a-17"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.aluminumFrames">
                                                                                                        <label
                                                                                                            for="a-17"
                                                                                                            class="form-check-label">Aluminum
                                                                                                            Frames</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-18"
                                                                                                            class="form-check-input"
                                                                                                            name="a-18"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.mannedReception">
                                                                                                        <label
                                                                                                            for="a-18"
                                                                                                            class="form-check-label">Manned
                                                                                                            Reception</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-19"
                                                                                                            class="form-check-input"
                                                                                                            name="a-19"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.storageSpace">
                                                                                                        <label
                                                                                                            for="a-19"
                                                                                                            class="form-check-label">Storage
                                                                                                            Space</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-20"
                                                                                                            class="form-check-input"
                                                                                                            name="a-20"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.marbleFloor">
                                                                                                        <label
                                                                                                            for="a-20"
                                                                                                            class="form-check-label">Marble
                                                                                                            Floor
                                                                                                            Type</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-21"
                                                                                                            class="form-check-input"
                                                                                                            name="a-21"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.airy">
                                                                                                        <label
                                                                                                            for="a-21"
                                                                                                            class="form-check-label">Airy</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-22"
                                                                                                            class="form-check-input"
                                                                                                            name="a-22"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.underfloorHeating">
                                                                                                        <label
                                                                                                            for="a-22"
                                                                                                            class="form-check-label">Underfloor
                                                                                                            Heating</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-23"
                                                                                                            class="form-check-input"
                                                                                                            name="a-23"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.solarWaterHeating">
                                                                                                        <label
                                                                                                            for="a-23"
                                                                                                            class="form-check-label">Solar
                                                                                                            Water
                                                                                                            Heating</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-24"
                                                                                                            class="form-check-input"
                                                                                                            name="a-24"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.attic">
                                                                                                        <label
                                                                                                            for="a-24"
                                                                                                            class="form-check-label">Attic</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-25"
                                                                                                            class="form-check-input"
                                                                                                            name="a-25"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.playroom">
                                                                                                        <label
                                                                                                            for="a-25"
                                                                                                            class="form-check-label">Playroom</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-26"
                                                                                                            class="form-check-input"
                                                                                                            name="a-26"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.satelliteReceiver">
                                                                                                        <label
                                                                                                            for="a-26"
                                                                                                            class="form-check-label">Satellite
                                                                                                            Receiver</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-27"
                                                                                                            class="form-check-input"
                                                                                                            name="a-27"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.chargingFacilities">
                                                                                                        <label
                                                                                                            for="a-27"
                                                                                                            class="form-check-label">Charging
                                                                                                            Facilities
                                                                                                            for Electric
                                                                                                            Car</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-28"
                                                                                                            class="form-check-input"
                                                                                                            name="a-28"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.balcony">
                                                                                                        <label
                                                                                                            for="a-28"
                                                                                                            class="form-check-label">Balcony</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-29"
                                                                                                            class="form-check-input"
                                                                                                            name="a-29"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.woodenFrames">
                                                                                                        <label
                                                                                                            for="a-29"
                                                                                                            class="form-check-label">Wooden
                                                                                                            Frames</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-30"
                                                                                                            class="form-check-input"
                                                                                                            name="a-30"
                                                                                                            type="checkbox"
                                                                                                            v-model="internalFeatures.waterCloset">
                                                                                                        <label
                                                                                                            for="a-30"
                                                                                                            class="form-check-label">Water
                                                                                                            Closet</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>External Features
                                                                                                (optional)</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.privateGarden">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">Private
                                                                                                            Garden</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.awning">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">Awning</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.sharedGarden">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">Shared
                                                                                                            Garden</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.facade">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">Facade</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.roadType">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">Road
                                                                                                            type
                                                                                                            asphalt</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.residentialZone">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">Residential
                                                                                                            Zone</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-7"
                                                                                                            class="form-check-input"
                                                                                                            name="a-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.accessForDisabilities">
                                                                                                        <label for="a-7"
                                                                                                            class="form-check-label">Access
                                                                                                            for people
                                                                                                            with
                                                                                                            disabilities</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-8"
                                                                                                            class="form-check-input"
                                                                                                            name="a-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.swimmingPool">
                                                                                                        <label for="a-8"
                                                                                                            class="form-check-label">Swimming
                                                                                                            pool</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-9"
                                                                                                            class="form-check-input"
                                                                                                            name="a-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.builtInBBQ">
                                                                                                        <label for="a-9"
                                                                                                            class="form-check-label">Built
                                                                                                            in
                                                                                                            BBQ</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-10"
                                                                                                            class="form-check-input"
                                                                                                            name="a-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.parkingSpace">
                                                                                                        <label
                                                                                                            for="a-10"
                                                                                                            class="form-check-label">Parking
                                                                                                            space</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-11"
                                                                                                            class="form-check-input"
                                                                                                            name="a-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.garden">
                                                                                                        <label
                                                                                                            for="a-11"
                                                                                                            class="form-check-label">Garden</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-12"
                                                                                                            class="form-check-input"
                                                                                                            name="a-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="externalFeatures.balcony">
                                                                                                        <label
                                                                                                            for="a-12"
                                                                                                            class="form-check-label">Balcony</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Suitable For
                                                                                                (optional)</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.student">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">Student</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.holidayHome">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">Holiday
                                                                                                            home</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.investment">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">Investment</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.shortTermRental">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">Short-term
                                                                                                            rental</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.commercialUse">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">Commercial
                                                                                                            use</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.medicalOffice">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">Medical
                                                                                                            office</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Contact Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Contact Information</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Name</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactName">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Email</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactEmail">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Phone</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactPhone">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="form-group">
                                                                                <div class="col-lg-12 col-md-12">
                                                                                    <button @click="submitForm"
                                                                                        class="btn btn-danger"
                                                                                        type="submit">Submit</button>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </section>
                                                        <!-- ============================ Submit Property End ================================== -->
                                                    </div>

                                                    <!-- Malaysia Tab -->
                                                    <div class="tab-pane fade" id="malaysia" role="tabpanel"
                                                        aria-labelledby="Malaysia-tab">
                                                        <!-- ============================ Submit Property Start ================================== -->
                                                        <section>
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="alert alert-danger" role="alert">
                                                                            <p class="m-0">If you don't have an account
                                                                                you can create one by <a href="#">Click
                                                                                    Here</a>
                                                                            </p>
                                                                        </div>

                                                                    </div>

                                                                    <!-- Submit Form -->
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="submit-page p-0">

                                                                            <!-- Malaysia Basic Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Malaysia Property Basic Information
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Property Title<a
                                                                                                    href="#"
                                                                                                    class="tip-topdata"
                                                                                                    data-tip="Property Title"><i
                                                                                                        class="ti-help"></i></a></label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="propertyTitle">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Status</label>
                                                                                            <select id="status"
                                                                                                class="form-control"
                                                                                                v-model="status">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">For
                                                                                                    Rent</option>
                                                                                                <option value="2">For
                                                                                                    Sale</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Property Type</label>
                                                                                            <select id="property_type"
                                                                                                class="form-control"
                                                                                                v-model="propertyType">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    Condominium</option>
                                                                                                <option value="2">
                                                                                                    Apartment</option>
                                                                                                <option value="3">Landed
                                                                                                    House</option>
                                                                                                <option value="4">
                                                                                                    Commercial</option>
                                                                                                <option value="5">
                                                                                                    Offices</option>
                                                                                                <option value="6">Land
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Property
                                                                                                Category</label>
                                                                                            <select
                                                                                                id="property_category"
                                                                                                class="form-control"
                                                                                                v-model="propertyCategory">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">New
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    Second-hand</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Surface Area</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="ft²"
                                                                                                v-model="surfaceArea">
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Lot Type</label>
                                                                                            <select
                                                                                                id="property_lot_type"
                                                                                                class="form-control"
                                                                                                v-model="lotType">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    International Lot
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    Bumi-released
                                                                                                </option>
                                                                                                <option value="3">Bumi
                                                                                                    Lot</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Price</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="RM"
                                                                                                v-model="price">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Price per ft²</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="RM"
                                                                                                v-model="pricePerSqft">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Bedrooms</label>
                                                                                            <select id="bedrooms"
                                                                                                class="form-control"
                                                                                                v-model="bedrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Bathrooms</label>
                                                                                            <select id="bathrooms"
                                                                                                class="form-control"
                                                                                                v-model="bathrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Gallery -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Gallery</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Upload
                                                                                                Gallery</label>
                                                                                            <form
                                                                                                action="https://shreethemes.net/upload-target"
                                                                                                class="dropzone dz-clickable primary-dropzone">
                                                                                                <div class="card">
                                                                                                    <div class="drag-area"
                                                                                                        @dragover.prevent="onDragOver"
                                                                                                        @dragleave.prevent="onDragLeave"
                                                                                                        @drop.prevent="onDrop">
                                                                                                        <span
                                                                                                            v-if="!isDragging">
                                                                                                            Drag & drop
                                                                                                            image here
                                                                                                            or
                                                                                                            <span
                                                                                                                class="select"
                                                                                                                role="button"
                                                                                                                @click="selectFiles">
                                                                                                                Choose
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <div v-else
                                                                                                            class="select">
                                                                                                            Drop images
                                                                                                            here</div>
                                                                                                        <input
                                                                                                            name="file"
                                                                                                            type="file"
                                                                                                            class="file"
                                                                                                            ref="fileInput"
                                                                                                            multiple
                                                                                                            @change="onFileSelect" />
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="container">
                                                                                                        <div class="image"
                                                                                                            v-for="(image, index) in images"
                                                                                                            :key="index">
                                                                                                            <span
                                                                                                                class="delete"
                                                                                                                @click="deleteImage(index)">&times;</span>
                                                                                                            <img
                                                                                                                :src="image.url" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Property Video -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Property Video</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Youtube Embed
                                                                                                link</label>
                                                                                            <input type="text"
                                                                                                class="form-control">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Location -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Location in Malaysia</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Address</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="address">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>City</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="city">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>State</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="state">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Zip Code</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="zipCode">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Malaysia Property Detailed Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Malaysia Property Detailed
                                                                                    Information</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Description</label>
                                                                                            <textarea
                                                                                                class="form-control h-120"
                                                                                                v-model="description"></textarea>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Building Age
                                                                                                (optional)</label>
                                                                                            <select id="building_age"
                                                                                                class="form-control"
                                                                                                v-model="buildingAge">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">0 - 5
                                                                                                    Years</option>
                                                                                                <option value="2">0 - 10
                                                                                                    Years</option>
                                                                                                <option value="3">0 - 15
                                                                                                    Years</option>
                                                                                                <option value="4">0 - 20
                                                                                                    Years</option>
                                                                                                <option value="5">20+
                                                                                                    Years</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Condition
                                                                                                (optional)</label>
                                                                                            <select
                                                                                                id="property_condition"
                                                                                                class="form-control"
                                                                                                v-model="condition">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">Like
                                                                                                    New</option>
                                                                                                <option value="2">
                                                                                                    Renovated</option>
                                                                                                <option value="3">Need
                                                                                                    Renovation</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Land Tenure</label>
                                                                                            <select id="land_tenure"
                                                                                                class="form-control"
                                                                                                v-model="landTenure">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    Freehold</option>
                                                                                                <option value="2">
                                                                                                    Leasehold</option>
                                                                                                <option value="3">
                                                                                                    99-years Leasehold
                                                                                                </option>
                                                                                                <option value="4">
                                                                                                    999-years Leasehold
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Land Title</label>
                                                                                            <select id="land_title"
                                                                                                class="form-control"
                                                                                                v-model="landTitle">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    Commercial Strata
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    Residential Strata
                                                                                                </option>
                                                                                                <option value="3">
                                                                                                    Individual Title
                                                                                                </option>
                                                                                                <option value="4">Master
                                                                                                    Title</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Land Size
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="acres"
                                                                                                v-model="landSize">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Total Units
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalUnits">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Total Car Park
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalCarPark">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Total Towers
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalTowers">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Total Floors</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalFloors">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Starting Unit Price
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="RM"
                                                                                                v-model="startingUnitPrice">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Highest Unit Price
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="RM"
                                                                                                v-model="highestUnitPrice">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Rooms
                                                                                                (optional)</label>
                                                                                            <select id="rooms"
                                                                                                class="form-control"
                                                                                                v-model="rooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Car Park
                                                                                                (optional)</label>
                                                                                            <select id="car_park"
                                                                                                class="form-control"
                                                                                                v-model="carPark">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Furnishing
                                                                                                (optional)</label>
                                                                                            <select id="furnishing"
                                                                                                class="form-control"
                                                                                                v-model="furnishing">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">Full
                                                                                                    Furnished</option>
                                                                                                <option value="2">
                                                                                                    Partial Furnished
                                                                                                </option>
                                                                                                <option value="3">Not
                                                                                                    Furnished</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Completion Year
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="completionYear">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Internal Features
                                                                                                (optional)</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.airConditioning">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">Air
                                                                                                            Conditioning</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.fullyFittedKitchen">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">Fully
                                                                                                            Fitted
                                                                                                            Kitchen</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.waterHeaters">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">Water
                                                                                                            Heaters</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.builtInWardrobes">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">Built-in
                                                                                                            Wardrobes</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.balcony">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">Balcony</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.ensuiteBathrooms">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">En-suite
                                                                                                            Bathrooms</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-7"
                                                                                                            class="form-check-input"
                                                                                                            name="a-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.highQualityFlooring">
                                                                                                        <label for="a-7"
                                                                                                            class="form-check-label">High-Quality
                                                                                                            Flooring</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-8"
                                                                                                            class="form-check-input"
                                                                                                            name="a-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.openPlanLayout">
                                                                                                        <label for="a-8"
                                                                                                            class="form-check-label">Open
                                                                                                            Plan
                                                                                                            Layout</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-9"
                                                                                                            class="form-check-input"
                                                                                                            name="a-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.highCeilings">
                                                                                                        <label for="a-9"
                                                                                                            class="form-check-label">High
                                                                                                            Ceilings</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-10"
                                                                                                            class="form-check-input"
                                                                                                            name="a-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.smartHomeFeatures">
                                                                                                        <label
                                                                                                            for="a-10"
                                                                                                            class="form-check-label">Smart
                                                                                                            Home
                                                                                                            Features</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-11"
                                                                                                            class="form-check-input"
                                                                                                            name="a-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.utilityStorageRoom">
                                                                                                        <label
                                                                                                            for="a-11"
                                                                                                            class="form-check-label">Utility/Storage
                                                                                                            Room</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-12"
                                                                                                            class="form-check-input"
                                                                                                            name="a-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.privateLiftLobby">
                                                                                                        <label
                                                                                                            for="a-12"
                                                                                                            class="form-check-label">Private
                                                                                                            Lift
                                                                                                            Lobby</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-13"
                                                                                                            class="form-check-input"
                                                                                                            name="a-13"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.homeOfficeSpace">
                                                                                                        <label
                                                                                                            for="a-13"
                                                                                                            class="form-check-label">Home
                                                                                                            Office
                                                                                                            Space</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-14"
                                                                                                            class="form-check-input"
                                                                                                            name="a-14"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.dualKeyAccess">
                                                                                                        <label
                                                                                                            for="a-14"
                                                                                                            class="form-check-label">Dual
                                                                                                            Key
                                                                                                            Access</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-15"
                                                                                                            class="form-check-input"
                                                                                                            name="a-15"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.pantryArea">
                                                                                                        <label
                                                                                                            for="a-15"
                                                                                                            class="form-check-label">Pantry
                                                                                                            Area</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-16"
                                                                                                            class="form-check-input"
                                                                                                            name="a-16"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.laundryArea">
                                                                                                        <label
                                                                                                            for="a-16"
                                                                                                            class="form-check-label">Laundry
                                                                                                            Area</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-17"
                                                                                                            class="form-check-input"
                                                                                                            name="a-17"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.highQualityFinishes">
                                                                                                        <label
                                                                                                            for="a-17"
                                                                                                            class="form-check-label">High-Quality
                                                                                                            Finishes</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-18"
                                                                                                            class="form-check-input"
                                                                                                            name="a-18"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.largeWindowsNaturalLight">
                                                                                                        <label
                                                                                                            for="a-18"
                                                                                                            class="form-check-label">Large
                                                                                                            Windows/Natural
                                                                                                            Light</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-19"
                                                                                                            class="form-check-input"
                                                                                                            name="a-19"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.soundproofing">
                                                                                                        <label
                                                                                                            for="a-19"
                                                                                                            class="form-check-label">Soundproofing</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-20"
                                                                                                            class="form-check-input"
                                                                                                            name="a-20"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.walkInCloset">
                                                                                                        <label
                                                                                                            for="a-20"
                                                                                                            class="form-check-label">Walk-In
                                                                                                            Closet</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-21"
                                                                                                            class="form-check-input"
                                                                                                            name="a-21"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.guestPowderRoom">
                                                                                                        <label
                                                                                                            for="a-21"
                                                                                                            class="form-check-label">Guest
                                                                                                            Powder
                                                                                                            Room</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-22"
                                                                                                            class="form-check-input"
                                                                                                            name="a-22"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.smartWiring">
                                                                                                        <label
                                                                                                            for="a-22"
                                                                                                            class="form-check-label">Smart
                                                                                                            Wiring</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-23"
                                                                                                            class="form-check-input"
                                                                                                            name="a-23"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.privateGarden">
                                                                                                        <label
                                                                                                            for="a-23"
                                                                                                            class="form-check-label">Private
                                                                                                            Garden</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-24"
                                                                                                            class="form-check-input"
                                                                                                            name="a-24"
                                                                                                            type="checkbox"
                                                                                                            v-model="malaysiainternalFeatures.emergencyEscapePlan">
                                                                                                        <label
                                                                                                            for="a-24"
                                                                                                            class="form-check-label">Emergency
                                                                                                            Escape
                                                                                                            Plan</label>
                                                                                                    </li>

                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Facilities
                                                                                                (optional)</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="f-1"
                                                                                                            class="form-check-input"
                                                                                                            name="f-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.swimmingPool">
                                                                                                        <label for="f-1"
                                                                                                            class="form-check-label">Swimming
                                                                                                            Pool</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-2"
                                                                                                            class="form-check-input"
                                                                                                            name="f-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.gymnasium">
                                                                                                        <label for="f-2"
                                                                                                            class="form-check-label">Gymnasium</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-3"
                                                                                                            class="form-check-input"
                                                                                                            name="f-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.security">
                                                                                                        <label for="f-3"
                                                                                                            class="form-check-label">24-Hour
                                                                                                            Security</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-4"
                                                                                                            class="form-check-input"
                                                                                                            name="f-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.parkingSpaces">
                                                                                                        <label for="f-4"
                                                                                                            class="form-check-label">Parking
                                                                                                            Spaces</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-5"
                                                                                                            class="form-check-input"
                                                                                                            name="f-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.childrensPlayground">
                                                                                                        <label for="f-5"
                                                                                                            class="form-check-label">Children’s
                                                                                                            Playground</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-6"
                                                                                                            class="form-check-input"
                                                                                                            name="f-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.multipurposeHall">
                                                                                                        <label for="f-6"
                                                                                                            class="form-check-label">Multipurpose
                                                                                                            Hall</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-7"
                                                                                                            class="form-check-input"
                                                                                                            name="f-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.bbqArea">
                                                                                                        <label for="f-7"
                                                                                                            class="form-check-label">BBQ
                                                                                                            Area</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-8"
                                                                                                            class="form-check-input"
                                                                                                            name="f-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.joggingTrack">
                                                                                                        <label for="f-8"
                                                                                                            class="form-check-label">Jogging
                                                                                                            Track</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-9"
                                                                                                            class="form-check-input"
                                                                                                            name="f-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.wadingPool">
                                                                                                        <label for="f-9"
                                                                                                            class="form-check-label">Wading
                                                                                                            Pool</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-10"
                                                                                                            class="form-check-input"
                                                                                                            name="f-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.saunaSteamRoom">
                                                                                                        <label
                                                                                                            for="f-10"
                                                                                                            class="form-check-label">Sauna/Steam
                                                                                                            Room</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-11"
                                                                                                            class="form-check-input"
                                                                                                            name="f-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.readingStudyRoom">
                                                                                                        <label
                                                                                                            for="f-11"
                                                                                                            class="form-check-label">Reading/Study
                                                                                                            Room</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-12"
                                                                                                            class="form-check-input"
                                                                                                            name="f-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.clubhouse">
                                                                                                        <label
                                                                                                            for="f-12"
                                                                                                            class="form-check-label">Clubhouse</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-13"
                                                                                                            class="form-check-input"
                                                                                                            name="f-13"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.managementOffice">
                                                                                                        <label
                                                                                                            for="f-13"
                                                                                                            class="form-check-label">Management
                                                                                                            Office</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-14"
                                                                                                            class="form-check-input"
                                                                                                            name="f-14"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.gardenOrGreenSpaces">
                                                                                                        <label
                                                                                                            for="f-14"
                                                                                                            class="form-check-label">Garden
                                                                                                            or Green
                                                                                                            Spaces</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-15"
                                                                                                            class="form-check-input"
                                                                                                            name="f-15"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.functionRoom">
                                                                                                        <label
                                                                                                            for="f-15"
                                                                                                            class="form-check-label">Function
                                                                                                            Room</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-16"
                                                                                                            class="form-check-input"
                                                                                                            name="f-16"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.tennisCourt">
                                                                                                        <label
                                                                                                            for="f-16"
                                                                                                            class="form-check-label">Tennis
                                                                                                            Court</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-17"
                                                                                                            class="form-check-input"
                                                                                                            name="f-17"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.badmintonCourt">
                                                                                                        <label
                                                                                                            for="f-17"
                                                                                                            class="form-check-label">Badminton
                                                                                                            Court</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-18"
                                                                                                            class="form-check-input"
                                                                                                            name="f-18"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.surau">
                                                                                                        <label
                                                                                                            for="f-18"
                                                                                                            class="form-check-label">Surau</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-19"
                                                                                                            class="form-check-input"
                                                                                                            name="f-19"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.coveredCarPark">
                                                                                                        <label
                                                                                                            for="f-19"
                                                                                                            class="form-check-label">Covered
                                                                                                            Car
                                                                                                            Park</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-20"
                                                                                                            class="form-check-input"
                                                                                                            name="f-20"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.liftLobby">
                                                                                                        <label
                                                                                                            for="f-20"
                                                                                                            class="form-check-label">Lift
                                                                                                            Lobby</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-21"
                                                                                                            class="form-check-input"
                                                                                                            name="f-21"
                                                                                                            type="checkbox"
                                                                                                            v-model="facilities.airbnbManagement">
                                                                                                        <label
                                                                                                            for="f-21"
                                                                                                            class="form-check-label">Airbnb
                                                                                                            Management</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Suitable For
                                                                                                (optional)</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="sf-1"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.student">
                                                                                                        <label
                                                                                                            for="sf-1"
                                                                                                            class="form-check-label">Student</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="sf-2"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.holidayHome">
                                                                                                        <label
                                                                                                            for="sf-2"
                                                                                                            class="form-check-label">Holiday
                                                                                                            home</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="sf-3"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.investment">
                                                                                                        <label
                                                                                                            for="sf-3"
                                                                                                            class="form-check-label">Investment</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="sf-4"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.shortTermRental">
                                                                                                        <label
                                                                                                            for="sf-4"
                                                                                                            class="form-check-label">Short-term
                                                                                                            rental</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="sf-5"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.commercialUse">
                                                                                                        <label
                                                                                                            for="sf-5"
                                                                                                            class="form-check-label">Commercial
                                                                                                            use</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="sf-6"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.medicalOffice">
                                                                                                        <label
                                                                                                            for="sf-6"
                                                                                                            class="form-check-label">Medical
                                                                                                            office</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Contact Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Contact Information</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Name</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactName">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Email</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactEmail">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Phone</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactPhone">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="form-group">
                                                                                <div class="col-lg-12 col-md-12">
                                                                                    <button @click="malaysiaSubmitForm"
                                                                                        class="btn btn-danger"
                                                                                        type="submit">Submit</button>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </section>
                                                        <!-- ============================ Submit Property End ================================== -->
                                                    </div>

                                                    <!-- Singapore Tab -->
                                                    <div class="tab-pane fade" id="singapore" role="tabpanel"
                                                        aria-labelledby="Singapore-tab">
                                                        <!-- ============================ Submit Property Start ================================== -->
                                                        <section>
                                                            <div class="container">
                                                                <div class="row">
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="alert alert-danger" role="alert">
                                                                            <p class="m-0">If you don't have an account
                                                                                you can create one by <a href="#">Click
                                                                                    Here</a>
                                                                            </p>
                                                                        </div>

                                                                    </div>

                                                                    <!-- Submit Form -->
                                                                    <div class="col-lg-12 col-md-12">

                                                                        <div class="submit-page p-0">

                                                                            <!-- Singapore Basic Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Singapore Property Basic Information
                                                                                </h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Property Title<a
                                                                                                    href="#"
                                                                                                    class="tip-topdata"
                                                                                                    data-tip="Property Title"><i
                                                                                                        class="ti-help"></i></a></label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="propertyTitle">
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Status</label>
                                                                                            <select id="status"
                                                                                                class="form-control"
                                                                                                v-model="status">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">For
                                                                                                    Rent</option>
                                                                                                <option value="2">For
                                                                                                    Sale</option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Property Type</label>
                                                                                            <select id="ptypes"
                                                                                                class="form-control"
                                                                                                v-model="propertyType">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    Condominium</option>
                                                                                                <option value="2">
                                                                                                    Apartment</option>
                                                                                                <option value="3">Landed
                                                                                                    House</option>
                                                                                                <option value="4">
                                                                                                    Commercial</option>
                                                                                                <option value="5">
                                                                                                    Offices</option>
                                                                                                <option value="6">Land
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Property
                                                                                                Category</label>
                                                                                            <select id="ptypes"
                                                                                                class="form-control"
                                                                                                v-model="propertyCategory">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">New
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    Second-hand</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Surface Area</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="ft²"
                                                                                                v-model="surfaceArea">
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Nearest MRT</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="nearestMRT">
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Price</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="SGD"
                                                                                                v-model="price">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Price per ft²</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="SGD"
                                                                                                v-model="pricePerSqft">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Bedrooms</label>
                                                                                            <select id="bedrooms"
                                                                                                class="form-control"
                                                                                                v-model="bedrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Bathrooms</label>
                                                                                            <select id="bathrooms"
                                                                                                class="form-control"
                                                                                                v-model="bathrooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Gallery -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Gallery</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Upload
                                                                                                Gallery</label>
                                                                                            <form
                                                                                                action="https://shreethemes.net/upload-target"
                                                                                                class="dropzone dz-clickable primary-dropzone">
                                                                                                <div class="card">
                                                                                                    <div class="drag-area"
                                                                                                        @dragover.prevent="onDragOver"
                                                                                                        @dragleave.prevent="onDragLeave"
                                                                                                        @drop.prevent="onDrop">
                                                                                                        <span
                                                                                                            v-if="!isDragging">
                                                                                                            Drag & drop
                                                                                                            image here
                                                                                                            or
                                                                                                            <span
                                                                                                                class="select"
                                                                                                                role="button"
                                                                                                                @click="selectFiles">
                                                                                                                Choose
                                                                                                            </span>
                                                                                                        </span>
                                                                                                        <div v-else
                                                                                                            class="select">
                                                                                                            Drop images
                                                                                                            here</div>
                                                                                                        <input
                                                                                                            name="file"
                                                                                                            type="file"
                                                                                                            class="file"
                                                                                                            ref="fileInput"
                                                                                                            multiple
                                                                                                            @change="onFileSelect" />
                                                                                                    </div>
                                                                                                    <div
                                                                                                        class="container">
                                                                                                        <div class="image"
                                                                                                            v-for="(image, index) in images"
                                                                                                            :key="index">
                                                                                                            <span
                                                                                                                class="delete"
                                                                                                                @click="deleteImage(index)">&times;</span>
                                                                                                            <img
                                                                                                                :src="image.url" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Property Video -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Property Video</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Youtube Embed
                                                                                                link</label>
                                                                                            <input type="text"
                                                                                                class="form-control">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Location -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Location in Singapore</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Address</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="address">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Neighbourhood</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="neighbourhood">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>District</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="district">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-6">
                                                                                            <label>Zip Code</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="zipCode">
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Singapore Property Detailed Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Singapore Property Detailed
                                                                                    Information</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Description</label>
                                                                                            <textarea
                                                                                                class="form-control h-120"
                                                                                                v-model="description"></textarea>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Building Age
                                                                                                (optional)</label>
                                                                                            <select id="bage"
                                                                                                class="form-control"
                                                                                                v-model="buildingAge">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">0 - 5
                                                                                                    Years</option>
                                                                                                <option value="2">0 - 10
                                                                                                    Years</option>
                                                                                                <option value="3">0 - 15
                                                                                                    Years</option>
                                                                                                <option value="4">0 - 20
                                                                                                    Years</option>
                                                                                                <option value="5">20+
                                                                                                    Years</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Condition
                                                                                                (optional)</label>
                                                                                            <select id="bage"
                                                                                                class="form-control"
                                                                                                v-model="condition">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">Like
                                                                                                    New</option>
                                                                                                <option value="2">
                                                                                                    Renovated</option>
                                                                                                <option value="3">Need
                                                                                                    Renovation</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Land Tenure</label>
                                                                                            <select id="garage"
                                                                                                class="form-control"
                                                                                                v-model="landTenure">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">
                                                                                                    Freehold</option>
                                                                                                <option value="2">
                                                                                                    Leasehold</option>
                                                                                                <option value="3">
                                                                                                    99-years Leasehold
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Type of
                                                                                                Property</label>
                                                                                            <select id="garage"
                                                                                                class="form-control"
                                                                                                v-model="typeOfProperty">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">Public
                                                                                                    Housing (HDB Flats)
                                                                                                </option>
                                                                                                <option value="2">
                                                                                                    Private Residential
                                                                                                    Properties</option>
                                                                                                <option value="3">
                                                                                                    Executive
                                                                                                    Condominiums
                                                                                                </option>
                                                                                                <option value="4">
                                                                                                    Commercial
                                                                                                    Properties</option>
                                                                                                <option value="5">
                                                                                                    Industrial
                                                                                                    Properties</option>
                                                                                                <option value="6">
                                                                                                    Mixed-Use
                                                                                                    Developments
                                                                                                </option>
                                                                                                <option value="7">
                                                                                                    Serviced Apartments
                                                                                                </option>
                                                                                                <option value="8">Hotels
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Land Size
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="acres"
                                                                                                v-model="landSize">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Total Units
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalUnits">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Total Car Park
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalCarPark">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Total Towers
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalTowers">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Total Floors
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="totalFloors">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Starting Unit Price
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="SGD"
                                                                                                v-model="startingUnitPrice">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Highest Unit Price
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                placeholder="SGD"
                                                                                                v-model="highestUnitPrice">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Rooms
                                                                                                (optional)</label>
                                                                                            <select id="rooms"
                                                                                                class="form-control"
                                                                                                v-model="rooms">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Car Park
                                                                                                (optional)</label>
                                                                                            <select id="rooms"
                                                                                                class="form-control"
                                                                                                v-model="carPark">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">1
                                                                                                </option>
                                                                                                <option value="2">2
                                                                                                </option>
                                                                                                <option value="3">3
                                                                                                </option>
                                                                                                <option value="4">4
                                                                                                </option>
                                                                                                <option value="5">5
                                                                                                </option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Furnishing
                                                                                                (optional)</label>
                                                                                            <select id="bage"
                                                                                                class="form-control"
                                                                                                v-model="furnishing">
                                                                                                <option value="">&nbsp;
                                                                                                </option>
                                                                                                <option value="1">Full
                                                                                                    Furnished</option>
                                                                                                <option value="2">
                                                                                                    Partial Furnished
                                                                                                </option>
                                                                                                <option value="3">Not
                                                                                                    Furnished</option>
                                                                                            </select>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Completion Year
                                                                                                (optional)</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="completionYear">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Internal Features
                                                                                                (optional)</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="a-1"
                                                                                                            class="form-check-input"
                                                                                                            name="a-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.airConditioning">
                                                                                                        <label for="a-1"
                                                                                                            class="form-check-label">Air
                                                                                                            Conditioning</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-2"
                                                                                                            class="form-check-input"
                                                                                                            name="a-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.fullyFittedKitchen">
                                                                                                        <label for="a-2"
                                                                                                            class="form-check-label">Fully
                                                                                                            Fitted
                                                                                                            Kitchen</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-3"
                                                                                                            class="form-check-input"
                                                                                                            name="a-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.waterHeaters">
                                                                                                        <label for="a-3"
                                                                                                            class="form-check-label">Water
                                                                                                            Heaters</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-4"
                                                                                                            class="form-check-input"
                                                                                                            name="a-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.builtInWardrobes">
                                                                                                        <label for="a-4"
                                                                                                            class="form-check-label">Built-in
                                                                                                            Wardrobes</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-5"
                                                                                                            class="form-check-input"
                                                                                                            name="a-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.balcony">
                                                                                                        <label for="a-5"
                                                                                                            class="form-check-label">Balcony</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-6"
                                                                                                            class="form-check-input"
                                                                                                            name="a-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.ensuiteBathrooms">
                                                                                                        <label for="a-6"
                                                                                                            class="form-check-label">En-suite
                                                                                                            Bathrooms</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-7"
                                                                                                            class="form-check-input"
                                                                                                            name="a-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.highQualityFlooring">
                                                                                                        <label for="a-7"
                                                                                                            class="form-check-label">High-Quality
                                                                                                            Flooring</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-8"
                                                                                                            class="form-check-input"
                                                                                                            name="a-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.openPlanLayout">
                                                                                                        <label for="a-8"
                                                                                                            class="form-check-label">Open
                                                                                                            Plan
                                                                                                            Layout</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-9"
                                                                                                            class="form-check-input"
                                                                                                            name="a-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.highCeilings">
                                                                                                        <label for="a-9"
                                                                                                            class="form-check-label">High
                                                                                                            Ceilings</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-10"
                                                                                                            class="form-check-input"
                                                                                                            name="a-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.smartHomeFeatures">
                                                                                                        <label
                                                                                                            for="a-10"
                                                                                                            class="form-check-label">Smart
                                                                                                            Home
                                                                                                            Features</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-11"
                                                                                                            class="form-check-input"
                                                                                                            name="a-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.utilityStorageRoom">
                                                                                                        <label
                                                                                                            for="a-11"
                                                                                                            class="form-check-label">Utility/Storage
                                                                                                            Room</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-12"
                                                                                                            class="form-check-input"
                                                                                                            name="a-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.privateLiftLobby">
                                                                                                        <label
                                                                                                            for="a-12"
                                                                                                            class="form-check-label">Private
                                                                                                            Lift
                                                                                                            Lobby</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-13"
                                                                                                            class="form-check-input"
                                                                                                            name="a-13"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.homeOfficeSpace">
                                                                                                        <label
                                                                                                            for="a-13"
                                                                                                            class="form-check-label">Home
                                                                                                            Office
                                                                                                            Space</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-14"
                                                                                                            class="form-check-input"
                                                                                                            name="a-14"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.dualKeyAccess">
                                                                                                        <label
                                                                                                            for="a-14"
                                                                                                            class="form-check-label">Dual
                                                                                                            Key
                                                                                                            Access</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-15"
                                                                                                            class="form-check-input"
                                                                                                            name="a-15"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.pantryArea">
                                                                                                        <label
                                                                                                            for="a-15"
                                                                                                            class="form-check-label">Pantry
                                                                                                            Area</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-16"
                                                                                                            class="form-check-input"
                                                                                                            name="a-16"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.laundryArea">
                                                                                                        <label
                                                                                                            for="a-16"
                                                                                                            class="form-check-label">Laundry
                                                                                                            Area</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-17"
                                                                                                            class="form-check-input"
                                                                                                            name="a-17"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.highQualityFinishes">
                                                                                                        <label
                                                                                                            for="a-17"
                                                                                                            class="form-check-label">High-Quality
                                                                                                            Finishes</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-18"
                                                                                                            class="form-check-input"
                                                                                                            name="a-18"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.largeWindowsNaturalLight">
                                                                                                        <label
                                                                                                            for="a-18"
                                                                                                            class="form-check-label">Large
                                                                                                            Windows/Natural
                                                                                                            Light</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-19"
                                                                                                            class="form-check-input"
                                                                                                            name="a-19"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.soundproofing">
                                                                                                        <label
                                                                                                            for="a-19"
                                                                                                            class="form-check-label">Soundproofing</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-20"
                                                                                                            class="form-check-input"
                                                                                                            name="a-20"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.walkInCloset">
                                                                                                        <label
                                                                                                            for="a-20"
                                                                                                            class="form-check-label">Walk-In
                                                                                                            Closet</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-21"
                                                                                                            class="form-check-input"
                                                                                                            name="a-21"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.guestPowderRoom">
                                                                                                        <label
                                                                                                            for="a-21"
                                                                                                            class="form-check-label">Guest
                                                                                                            Powder
                                                                                                            Room</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-22"
                                                                                                            class="form-check-input"
                                                                                                            name="a-22"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.smartWiring">
                                                                                                        <label
                                                                                                            for="a-22"
                                                                                                            class="form-check-label">Smart
                                                                                                            Wiring</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-23"
                                                                                                            class="form-check-input"
                                                                                                            name="a-23"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.privateGarden">
                                                                                                        <label
                                                                                                            for="a-23"
                                                                                                            class="form-check-label">Private
                                                                                                            Garden</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="a-24"
                                                                                                            class="form-check-input"
                                                                                                            name="a-24"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeInternalFeatures.emergencyEscapePlan">
                                                                                                        <label
                                                                                                            for="a-24"
                                                                                                            class="form-check-label">Emergency
                                                                                                            Escape
                                                                                                            Plan</label>
                                                                                                    </li>

                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Facilities
                                                                                                (optional)</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="f-1"
                                                                                                            class="form-check-input"
                                                                                                            name="f-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.swimmingPool">
                                                                                                        <label for="f-1"
                                                                                                            class="form-check-label">Swimming
                                                                                                            Pool</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-2"
                                                                                                            class="form-check-input"
                                                                                                            name="f-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.gymnasium">
                                                                                                        <label for="f-2"
                                                                                                            class="form-check-label">Gymnasium</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-3"
                                                                                                            class="form-check-input"
                                                                                                            name="f-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.security">
                                                                                                        <label for="f-3"
                                                                                                            class="form-check-label">24-Hour
                                                                                                            Security</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-4"
                                                                                                            class="form-check-input"
                                                                                                            name="f-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.parkingSpaces">
                                                                                                        <label for="f-4"
                                                                                                            class="form-check-label">Parking
                                                                                                            Spaces</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-5"
                                                                                                            class="form-check-input"
                                                                                                            name="f-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.childrensPlayground">
                                                                                                        <label for="f-5"
                                                                                                            class="form-check-label">Children’s
                                                                                                            Playground</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-6"
                                                                                                            class="form-check-input"
                                                                                                            name="f-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.multipurposeHall">
                                                                                                        <label for="f-6"
                                                                                                            class="form-check-label">Multipurpose
                                                                                                            Hall</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-7"
                                                                                                            class="form-check-input"
                                                                                                            name="f-7"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.bbqArea">
                                                                                                        <label for="f-7"
                                                                                                            class="form-check-label">BBQ
                                                                                                            Area</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-8"
                                                                                                            class="form-check-input"
                                                                                                            name="f-8"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.joggingTrack">
                                                                                                        <label for="f-8"
                                                                                                            class="form-check-label">Jogging
                                                                                                            Track</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-9"
                                                                                                            class="form-check-input"
                                                                                                            name="f-9"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.wadingPool">
                                                                                                        <label for="f-9"
                                                                                                            class="form-check-label">Wading
                                                                                                            Pool</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-10"
                                                                                                            class="form-check-input"
                                                                                                            name="f-10"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.saunaSteamRoom">
                                                                                                        <label
                                                                                                            for="f-10"
                                                                                                            class="form-check-label">Sauna/Steam
                                                                                                            Room</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-11"
                                                                                                            class="form-check-input"
                                                                                                            name="f-11"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.readingStudyRoom">
                                                                                                        <label
                                                                                                            for="f-11"
                                                                                                            class="form-check-label">Reading/Study
                                                                                                            Room</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-12"
                                                                                                            class="form-check-input"
                                                                                                            name="f-12"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.clubhouse">
                                                                                                        <label
                                                                                                            for="f-12"
                                                                                                            class="form-check-label">Clubhouse</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-13"
                                                                                                            class="form-check-input"
                                                                                                            name="f-13"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.managementOffice">
                                                                                                        <label
                                                                                                            for="f-13"
                                                                                                            class="form-check-label">Management
                                                                                                            Office</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-14"
                                                                                                            class="form-check-input"
                                                                                                            name="f-14"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.gardenOrGreenSpaces">
                                                                                                        <label
                                                                                                            for="f-14"
                                                                                                            class="form-check-label">Garden
                                                                                                            or Green
                                                                                                            Spaces</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-15"
                                                                                                            class="form-check-input"
                                                                                                            name="f-15"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.functionRoom">
                                                                                                        <label
                                                                                                            for="f-15"
                                                                                                            class="form-check-label">Function
                                                                                                            Room</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-16"
                                                                                                            class="form-check-input"
                                                                                                            name="f-16"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.tennisCourt">
                                                                                                        <label
                                                                                                            for="f-16"
                                                                                                            class="form-check-label">Tennis
                                                                                                            Court</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-17"
                                                                                                            class="form-check-input"
                                                                                                            name="f-17"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.badmintonCourt">
                                                                                                        <label
                                                                                                            for="f-17"
                                                                                                            class="form-check-label">Badminton
                                                                                                            Court</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-18"
                                                                                                            class="form-check-input"
                                                                                                            name="f-18"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.surau">
                                                                                                        <label
                                                                                                            for="f-18"
                                                                                                            class="form-check-label">Surau</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-19"
                                                                                                            class="form-check-input"
                                                                                                            name="f-19"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.coveredCarPark">
                                                                                                        <label
                                                                                                            for="f-19"
                                                                                                            class="form-check-label">Covered
                                                                                                            Car
                                                                                                            Park</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-20"
                                                                                                            class="form-check-input"
                                                                                                            name="f-20"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.liftLobby">
                                                                                                        <label
                                                                                                            for="f-20"
                                                                                                            class="form-check-label">Lift
                                                                                                            Lobby</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="f-21"
                                                                                                            class="form-check-input"
                                                                                                            name="f-21"
                                                                                                            type="checkbox"
                                                                                                            v-model="singaporeFacilities.airbnbManagement">
                                                                                                        <label
                                                                                                            for="f-21"
                                                                                                            class="form-check-label">Airbnb
                                                                                                            Management</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-12">
                                                                                            <label>Suitable For
                                                                                                (optional)</label>
                                                                                            <div class="o-features">
                                                                                                <ul
                                                                                                    class="no-ul-list third-row">
                                                                                                    <li>
                                                                                                        <input id="sf-1"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-1"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.student">
                                                                                                        <label
                                                                                                            for="sf-1"
                                                                                                            class="form-check-label">Student</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="sf-2"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-2"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.holidayHome">
                                                                                                        <label
                                                                                                            for="sf-2"
                                                                                                            class="form-check-label">Holiday
                                                                                                            home</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="sf-3"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-3"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.investment">
                                                                                                        <label
                                                                                                            for="sf-3"
                                                                                                            class="form-check-label">Investment</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="sf-4"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-4"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.shortTermRental">
                                                                                                        <label
                                                                                                            for="sf-4"
                                                                                                            class="form-check-label">Short-term
                                                                                                            rental</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="sf-5"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-5"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.commercialUse">
                                                                                                        <label
                                                                                                            for="sf-5"
                                                                                                            class="form-check-label">Commercial
                                                                                                            use</label>
                                                                                                    </li>
                                                                                                    <li>
                                                                                                        <input id="sf-6"
                                                                                                            class="form-check-input"
                                                                                                            name="sf-6"
                                                                                                            type="checkbox"
                                                                                                            v-model="suitableFor.medicalOffice">
                                                                                                        <label
                                                                                                            for="sf-6"
                                                                                                            class="form-check-label">Medical
                                                                                                            office</label>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <!-- Contact Information -->
                                                                            <div class="frm_submit_block">
                                                                                <h3>Contact Information</h3>
                                                                                <div class="frm_submit_wrap">
                                                                                    <div class="form-row row">

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Name</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactName">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Email</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactEmail">
                                                                                        </div>

                                                                                        <div
                                                                                            class="form-group col-md-4">
                                                                                            <label>Phone</label>
                                                                                            <input type="text"
                                                                                                class="form-control"
                                                                                                v-model="contactPhone">
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div class="form-group">
                                                                                <div class="col-lg-12 col-md-12">
                                                                                    <button @click="singaporeSubmitForm"
                                                                                        class="btn btn-danger"
                                                                                        type="submit">Submit</button>
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </section>
                                                        <!-- ============================ Submit Property End ================================== -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /row -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ================= Our Mission ================= -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, auth } from '../../firebase'; // Import the auth object
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
} from 'firebase/auth';
import { defineComponent } from 'vue';

import 'magnific-popup';

import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';

export default defineComponent({
    name: 'PropertySubmission',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            forgotPasswordEmail: '',
            resetPasswordError: '',
            resetPasswordSuccess: '',
            selectedLanguage: this.$i18n.locale,
            phoneNumber: '',
            loginEmail: '',
            loginPassword: '',
            fullName: '',
            registerEmail: '',
            registerPassword: '',
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            oldIcon,
            navMenuVisible: false,
            activeSubmenu: null,
            userLoggedIn: false,
            loginError: '',
            propertyTitle: '',
            propertyStatus: '',
            propertyType: '',
            propertyCategory: '',
            surfaceArea: '',
            plotSurfaceArea: '',
            price: '',
            pricePerSqM: '',
            bedrooms: '',
            bathrooms: '',
            gallery: [],
            address: '',
            city: '',
            state: '',
            zipCode: '',
            description: '',
            buildingAge: '',
            garage: '',
            rooms: '',
            heatingSystem: '',
            energyClass: '',
            completionYear: '',
            internalFeatures: {
                fullFurnished: false,
                partialFurnished: false,
                noFurnished: false,
                elevator: false,
                internalStaircase: false,
                nightPower: false,
                secureDoor: false,
                doubleGlass: false,
                painted: false,
                wifi: false,
                fireplace: false,
                woodFloor: false,
                alarm: false,
                windowScreens: false,
                airConditioning: false,
                petsWelcome: false,
                aluminumFrames: false,
                mannedReception: false,
                storageSpace: false,
                marbleFloor: false,
                airy: false,
                underfloorHeating: false,
                solarWaterHeating: false,
                attic: false,
                playroom: false,
                satelliteReceiver: false,
                chargingFacilities: false,
                balcony: false,
                woodenFrames: false,
                waterCloset: false,
            },
            externalFeatures: {
                privateGarden: false,
                awning: false,
                sharedGarden: false,
                facade: false,
                roadType: false,
                residentialZone: false,
                accessForDisabilities: false,
                swimmingPool: false,
                builtInBBQ: false,
                parkingSpace: false,
                garden: false,
                balcony: false,
            },
            suitableFor: {
                student: false,
                holidayHome: false,
                investment: false,
                shortTermRental: false,
                commercialUse: false,
                medicalOffice: false,
            },
            contactName: '',
            contactEmail: '',
            contactPhone: '',
            status: '',
            lotType: '',
            pricePerSqft: '',
            landSize: '',
            totalUnits: '',
            totalCarPark: '',
            totalTowers: '',
            totalFloors: '',
            startingUnitPrice: '',
            highestUnitPrice: '',
            carPark: '',
            furnishing: '',
            malaysiainternalFeatures: {
                airConditioning: false,
                fullyFittedKitchen: false,
                waterHeaters: false,
                builtInWardrobes: false,
                balcony: false,  // Also part of externalFeatures in Greece property
                ensuiteBathrooms: false,
                highQualityFlooring: false,
                openPlanLayout: false,
                highCeilings: false,
                smartHomeFeatures: false,
                utilityStorageRoom: false,
                privateLiftLobby: false,
                homeOfficeSpace: false,
                dualKeyAccess: false,
                pantryArea: false,
                laundryArea: false,
                highQualityFinishes: false,
                largeWindowsNaturalLight: false,
                soundproofing: false,
                walkInCloset: false,
                guestPowderRoom: false,
                smartWiring: false,
                privateGarden: false,
                emergencyEscapePlan: false,
            },
            facilities: {
                swimmingPool: false,
                gymnasium: false,
                security: false,
                parkingSpaces: false,
                childrensPlayground: false,
                multipurposeHall: false,
                bbqArea: false,
                joggingTrack: false,
                wadingPool: false,
                saunaSteamRoom: false,
                readingStudyRoom: false,
                clubhouse: false,
                managementOffice: false,
                gardenOrGreenSpaces: false,
                functionRoom: false,
                tennisCourt: false,
                badmintonCourt: false,
                surau: false,
                coveredCarPark: false,
                liftLobby: false,
                airbnbManagement: false,
            },
            singaporeInternalFeatures: {
                airConditioning: false,
                fullyFittedKitchen: false,
                waterHeaters: false,
                builtInWardrobes: false,
                balcony: false,
                ensuiteBathrooms: false,
                highQualityFlooring: false,
                openPlanLayout: false,
                highCeilings: false,
                smartHomeFeatures: false,
                utilityStorageRoom: false,
                privateLiftLobby: false,
                homeOfficeSpace: false,
                dualKeyAccess: false,
                pantryArea: false,
                laundryArea: false,
                highQualityFinishes: false,
                largeWindowsNaturalLight: false,
                soundproofing: false,
                walkInCloset: false,
                guestPowderRoom: false,
                smartWiring: false,
                privateGarden: false,
                emergencyEscapePlan: false,
            },
            singaporeFacilities: {
                swimmingPool: false,
                gymnasium: false,
                security: false,
                parkingSpaces: false,
                childrensPlayground: false,
                multipurposeHall: false,
                bbqArea: false,
                joggingTrack: false,
                wadingPool: false,
                saunaSteamRoom: false,
                readingStudyRoom: false,
                clubhouse: false,
                managementOffice: false,
                gardenOrGreenSpaces: false,
                functionRoom: false,
                tennisCourt: false,
                badmintonCourt: false,
                surau: false,
                coveredCarPark: false,
                liftLobby: false,
                airbnbManagement: false,
            },
            typeOfProperty: '',
            nearestMRT: '',
            neighbourhood: '',
            district: '',
            landTenure: '',
            condition: '',
            landTitle: '',
            modal: false,
            images: [], // Drag and drop images
            isDragging: false, // Drag and drop state
        };
    },
    methods: {
        selectFiles() {
            this.$refs.fileInput.click();
        },
        onFileSelect(event) {
            const files = event.target.files;
            if (files.length === 0) return;
            for (let i = 0; i < files.length; i++) {
                if (files[i].type.split("/")[0] !== "image") continue;
                if (!this.images.some((e) => e.name === files[i].name)) {
                    this.images.push({ name: files[i].name, url: URL.createObjectURL(files[i]) });
                }
            }
        },
        deleteImage(index) {
            this.images.splice(index, 1);
        },
        onDragOver(event) {
            event.preventDefault();
            this.isDragging = true;
            event.dataTransfer.dropEffect = "copy";
        },
        onDragLeave(event) {
            event.preventDefault();
            this.isDragging = false;
        },
        onDrop(event) {
            event.preventDefault();
            this.isDragging = false;
            const files = event.dataTransfer.files;
            for (let i = 0; i < files.length; i++) {
                if (files[i].type.split("/")[0] !== "image") continue;
                if (!this.images.some((e) => e.name === files[i].name)) {
                    this.images.push({ name: files[i].name, url: URL.createObjectURL(files[i]) });
                }
            }
        },
        async submitForm() {
            try {
                await addDoc(collection(db, 'greecepropertyform'), {
                    propertyTitle: this.propertyTitle,
                    propertyStatus: this.propertyStatus,
                    propertyType: this.propertyType,
                    propertyCategory: this.propertyCategory,
                    surfaceArea: this.surfaceArea,
                    plotSurfaceArea: this.plotSurfaceArea,
                    price: this.price,
                    pricePerSqM: this.pricePerSqM,
                    bedrooms: this.bedrooms,
                    bathrooms: this.bathrooms,
                    gallery: this.gallery,
                    address: this.address,
                    city: this.city,
                    state: this.state,
                    zipCode: this.zipCode,
                    description: this.description,
                    buildingAge: this.buildingAge,
                    garage: this.garage,
                    rooms: this.rooms,
                    heatingSystem: this.heatingSystem,
                    energyClass: this.energyClass,
                    completionYear: this.completionYear,
                    internalFeatures: this.internalFeatures,
                    externalFeatures: this.externalFeatures,
                    suitableFor: this.suitableFor,
                    contactName: this.contactName,
                    contactEmail: this.contactEmail,
                    contactPhone: this.contactPhone,
                    createdAt: serverTimestamp(),
                });

                alert('Greece Property added successfully!');
            } catch (error) {
                console.error('Error adding Greece property: ', error);
                alert('Failed to add Greece property. Please try again.');
            }
        },
        async malaysiaSubmitForm() {
            try {
                await addDoc(collection(db, 'malaysiapropertyform'), {
                    // Other fields here...
                    gallery: this.gallery,  // Updated gallery field
                    createdAt: serverTimestamp(),
                });

                alert('Malaysia Property added successfully!');
            } catch (error) {
                console.error('Error adding Malaysia property: ', error);
                alert('Failed to add Malaysia property. Please try again.');
            }
        },
        async singaporeSubmitForm() {
            try {
                await addDoc(collection(db, 'singaporepropertyform'), {
                    // Other fields here...
                    gallery: this.gallery,  // Updated gallery field
                    createdAt: serverTimestamp(),
                });

                alert('Singapore Property added successfully!');
            } catch (error) {
                console.error('Error adding Singapore property: ', error);
                alert('Failed to add Singapore property. Please try again.');
            }
        },
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
        async handleLogin() {
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    this.loginEmail,
                    this.loginPassword
                );
                console.log('User logged in:', userCredential.user);
                this.userLoggedIn = true;
                this.loginError = '';
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error logging in:', error.message);
                this.loginError = 'Invalid username or password. Please try again.';
            }
        },
        async handleRegister() {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    this.registerEmail,
                    this.registerPassword
                );
                console.log('User registered:', userCredential.user);
                this.userLoggedIn = true;
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error registering:', error.message);
            }
        },
        async handleLogout() {
            try {
                await signOut(auth);
                this.userLoggedIn = false;
                console.log('User logged out');
            } catch (error) {
                console.error('Error logging out:', error.message);
            }
        },
        changeLanguage() {
            console.log("Language changing to:", this.selectedLanguage);
            this.$i18n.locale = this.selectedLanguage;
            localStorage.setItem('userLanguage', this.selectedLanguage);
            this.$forceUpdate();
        },
        async handleForgotPassword() {
            try {
                await sendPasswordResetEmail(auth, this.forgotPasswordEmail);
                this.resetPasswordSuccess = 'A password reset email has been sent to your email address.';
                this.resetPasswordError = '';
            } catch (error) {
                console.error('Error sending password reset email:', error.message);
                this.resetPasswordError = 'Failed to send password reset email. Please try again.';
                this.resetPasswordSuccess = '';
            }
        },
    },
    created() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userLoggedIn = true;
            } else {
                this.userLoggedIn = false;
            }
        });
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) {
            this.selectedLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
        }
    },
});
</script>

<style scoped>
.card {
    width: 100%;
    padding: 10px;
    box-shadow: 0 0 5px #fdfdfd;
    border-radius: 5px;
    overflow: hidden;
}

.card .top {
    text-align: center;
}

.card p {
    font-weight: bold;
    color: #fe0000;
}

.card button {
    outline: 0;
    border: 0;
    color: #fff;
    border-radius: 4px;
    font-weight: 400;
    padding: 8px 13px;
    width: 100%;
    background: #fe0000;
}

.card .drag-area {
    height: 150px;
    border-radius: 5px;
    border: 2px dashed #fe0000;
    background: #f4f3f9;
    color: #fe0000;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-user-select: none;
    margin-top: 10px;
}

.card .drag-area .visible {
    font-size: 18px;
}

.card .select {
    color: #5256ad;
    margin-left: 5px;
    cursor: pointer;
    transition: 0.4s;
}

.card .select:hover {
    opacity: 0.6;
}

.card .container {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    max-height: 200px;
    position: relative;
    margin-bottom: 8px;
    padding-top: 15px;
}

.card .container .image {
    width: 75px;
    margin-right: 5px;
    height: 75px;
    position: relative;
    margin-bottom: 8px;
}

.card .container .image img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
}

.card .container .image span {
    position: absolute;
    top: -2px;
    right: 9px;
    font-size: 20px;
    cursor: pointer;
}

.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 70px;
    margin-left: 50px;
}
</style>
