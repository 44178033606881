<template>
		<!-- ============================ Call To Action ================================== -->
		<section class="bg-danger call_action_wrap-wrap">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="call_action_wrap">
							<div class="call_action_wrap-head">
								<h3>{{ $t('cta.do_you_have_questions') }}</h3>
								<span>{{ $t('cta.help_to_find_home') }}</span>
							</div>
							<a href="#" class="btn btn-call_action_wrap">{{ $t('cta.contact_us_now') }}</a>
						</div>
					</div>
				</div>
			</div>
		</section>
		<!-- ============================ Call To Action End ================================== -->
		<!-- ============================ Footer Start ================================== -->
		<footer class="dark-footer skin-dark-footer style-2">
			<div class="footer-middle">
				<div class="container">
					<div class="row">
						<div class="col-lg-5 col-md-5">
							<div class="footer_widget">
								<img :src="globalHomeLogo" class="logo img-footer small mb-4" alt="Global Home" />
								<h4 class="extream mb-3">{{ $t('footer.need_help') }}</h4>
								<p>{{ $t('footer.email') }}: support@globalahome.com {{ $t('footer.hotline') }}: +1
									929-578-6566</p>
								<p>{{ $t('footer.offices') }}: Singapore</p>
								<div class="foot-news-last mt-4">
									<div class="input-group">
										<input type="text" class="form-control"
											:placeholder="$t('footer.email_address')">
										<div class="input-group-append">
											<button type="button" class="btn btn-danger b-0 text-light">{{
												$t('footer.subscribe') }}</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="col-lg-6 col-md-7 ms-auto">
							<div class="row">
								<div class="col-lg-4 col-md-4">
									<div class="footer_widget">
										<h4 class="widget_title">{{ $t('footer.services') }}</h4>
										<ul class="footer-menu">
											<li><a href="#">{{ $t('footer.buy_property') }}</a></li>
											<li><a href="#">{{ $t('footer.sell_property') }}</a></li>
											<li><a href="#">{{ $t('footer.property_for_rent') }}</a></li>
											<li><a href="#">{{ $t('footer.global_partners') }}</a></li>
											<li><router-link to="/global-visa">{{ $t('footer.global_visa')
													}}</router-link>
											</li>
										</ul>
									</div>
								</div>

								<div class="col-lg-4 col-md-4">
									<div class="footer_widget">
										<h4 class="widget_title">{{ $t('footer.countries') }}</h4>
										<ul class="footer-menu">
											<li><a href="#">{{ $t('footer.greece') }}</a></li>
											<li><a href="#">{{ $t('footer.singapore') }}</a></li>
											<li><a href="#">{{ $t('footer.malaysia') }}</a></li>
											<li><a href="#">{{ $t('footer.indonesia') }}</a></li>
											<li><a href="#">{{ $t('footer.japan') }}</a></li>
											<li><a href="#">{{ $t('footer.germany') }}</a></li>
										</ul>
									</div>
								</div>

								<div class="col-lg-4 col-md-4">
									<div class="footer_widget">
										<h4 class="widget_title">{{ $t('footer.company') }}</h4>
										<ul class="footer-menu">
											<li><a href="#">{{ $t('footer.about') }}</a></li>
											<li><a href="#">{{ $t('footer.services') }}</a></li>
											<li><a href="#">{{ $t('footer.property_listings') }}</a></li>
											<li><a href="#">{{ $t('footer.blog_news') }}</a></li>
											<li><a href="#">{{ $t('footer.our_team') }}</a></li>
											<li><a href="#">{{ $t('footer.contact_us') }}</a></li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="footer-bottom">
				<div class="container">
					<div class="row align-items-center">
						<div class="col-lg-12 col-md-12 text-center">
							<p class="mb-0">© 2024 Global A Home {{ $t('footer.footer') }}</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
		<!-- ============================ Footer End ================================== -->
</template>

<script>
import { auth } from '../../firebase'; // Import the auth object
import {
	signInWithEmailAndPassword,
	createUserWithEmailAndPassword,
	signOut,
	onAuthStateChanged,
	sendPasswordResetEmail,
} from 'firebase/auth';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import bannerHome from '@/assets/img/banner-home.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';

import { db } from '../../firebase';
import { collection, getDocs, query, where, limit } from 'firebase/firestore';


export default {
	name: 'PageFooter',
	data() {
		return {
			addProperty,
			menuWidget,
			profileLogo,
			forgotPasswordEmail: '',
			resetPasswordError: '',
			resetPasswordSuccess: '',
			selectedLanguage: this.$i18n.locale,
			phoneNumber: '',
			loginEmail: '',
			loginPassword: '',
			fullName: '',
			registerEmail: '',
			registerPassword: '',
			globalHomeLogo,
			whatsappLogo,
			wechatLogo,
			loginLogo,
			settingsLogo,
			qrCodeLogo,
			bannerHome,
			navMenuVisible: false,
			activeSubmenu: null,
			selectedCategory: 'Buy',
			userLoggedIn: false,
			loginError: '',
		};
	},
	async mounted() {
		await this.fetchProperties();
	},
	methods: {
		toggleNavMenu() {
			console.log("Toggling navMenuVisible:", this.navMenuVisible);
			this.navMenuVisible = !this.navMenuVisible;
			console.log("New navMenuVisible state:", this.navMenuVisible);
		},
		toggleSubmenu(menu) {
			this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
		},
		async fetchProperties() {
			const q = query(
				collection(db, 'property_listings'),
				where('glb_id', '>=', 'GLB_GR_'),
				where('glb_id', '<', 'GLB_GR_\uf8ff'),
				limit(3)
			);
			const querySnapshot = await getDocs(q);

			// Map the querySnapshot to extract the data
			this.properties = querySnapshot.docs.map(doc => doc.data());

			const s = query(
				collection(db, 'property_listings'),
				where('glb_id', '>=', 'GLB_MY_SALE_'),
				where('glb_id', '<', 'GLB_MY_SALE_\uf8ff'),
				limit(3)
			);
			const querySnapshot_Malaysia = await getDocs(s);

			// Map the querySnapshot to extract the data
			this.my_properties = querySnapshot_Malaysia.docs.map(doc => doc.data());

			const r = query(
				collection(db, 'property_listings'),
				where('glb_id', '>=', 'GLB_SG_SALE_'),
				where('glb_id', '<', 'GLB_SG_SALE_\uf8ff'),
				limit(3)
			);
			const querySnapshot_Singapore = await getDocs(r);

			// Map the querySnapshot to extract the data
			this.sg_properties = querySnapshot_Singapore.docs.map(doc => doc.data());
			// const propertiesCollection = collection(db, "property_listings");
			// const propertySnapshot = await getDocs(propertiesCollection);
			// this.properties = propertySnapshot.docs.map(doc => doc.data());
		},
		async handleForgotPassword() {
			try {
				await sendPasswordResetEmail(auth, this.forgotPasswordEmail);
				this.resetPasswordSuccess = 'A password reset email has been sent to your email address.';
				this.resetPasswordError = '';
			} catch (error) {
				console.error('Error sending password reset email:', error.message);
				this.resetPasswordError = 'Failed to send password reset email. Please try again.';
				this.resetPasswordSuccess = '';
			}
		},
		async handleLogin() {
			try {
				const userCredential = await signInWithEmailAndPassword(
					auth,
					this.loginEmail,
					this.loginPassword
				);
				// Handle successful login
				console.log('User logged in:', userCredential.user);
				this.userLoggedIn = true; // Set login state to true
				this.loginError = '';
				this.$refs.closeBtn.click();
			} catch (error) {
				console.error('Error logging in:', error.message);
				this.loginError = 'Invalid username or password. Please try again.';
			}
		},
		async handleRegister() {
			try {
				const userCredential = await createUserWithEmailAndPassword(
					auth,
					this.registerEmail,
					this.registerPassword
				);
				// Handle successful registration
				console.log('User registered:', userCredential.user);
				this.userLoggedIn = true; // Set login state to true
				this.$refs.closeBtn.click();
			} catch (error) {
				console.error('Error registering:', error.message);
				// Handle errors (e.g., show an error message)
			}
		},
		async handleLogout() {
			try {
				await signOut(auth);
				this.userLoggedIn = false; // Set login state to false
				console.log('User logged out');
			} catch (error) {
				console.error('Error logging out:', error.message);
			}
		},
		getRouteNameByGlbId(glb_id) {
			if (glb_id.startsWith('GLB_GR_')) {
				return 'GreeceListing';
			} else if (glb_id.startsWith('GLB_MY_SALE_NEW_')) {
				return 'MalaysiaListing';
			} else if (glb_id.startsWith('GLB_MY_SALE_SECOND_')) {
				return 'MalaysiaSubsaleListing';
			} else if (glb_id.startsWith('GLB_MY_RENT_')) {
				return 'MalaysiaRentListing';
			} else if (glb_id.startsWith('GLB_SG_SALE_SECOND_')) {
				return 'SingaporeSubsaleListing';
			} else if (glb_id.startsWith('GLB_SG_RENT_')) {
				return 'SingaporeRentListing';
			} else if (glb_id.startsWith('GLB_SG_SALE_NEW_')) {
				return 'SingaporeListing';
			} else {
				return 'NotFound';
			}
		},
		updateCategory(category) {
			this.selectedCategory = category;
			// this.searchProperties(); // Trigger the search when the category changes
		},
		searchProperties() {
			const queryParams = {
				category: this.selectedCategory,
				location: this.selectedLocation || undefined,  // Avoid empty params
				propertyType: this.selectedPropertyType || undefined,
				priceRange: this.selectedPriceRange || undefined,
			};

			// Remove undefined properties from queryParams
			Object.keys(queryParams).forEach(key => queryParams[key] === undefined && delete queryParams[key]);

			this.$router.push({ path: '/property-listing', query: queryParams });
		},
		changeLanguage() {
			console.log("Language changing to:", this.selectedLanguage);
			this.$i18n.locale = this.selectedLanguage;
			localStorage.setItem('userLanguage', this.selectedLanguage);
			this.$forceUpdate();
		},
	},
	created() {
		onAuthStateChanged(auth, (user) => {
			if (user) {
				this.userLoggedIn = true;
			} else {
				this.userLoggedIn = false;
			}
		});
		const savedLanguage = localStorage.getItem('userLanguage');
		if (savedLanguage) {
			this.selectedLanguage = savedLanguage;
			this.$i18n.locale = savedLanguage;
		}
		
	},
};

</script>

<style scoped>
.nav-container {
	margin-bottom: 0px;
	display: flex;
	justify-content: flex-end;
	/* Aligns the button to the right */
}

.nav-dropdown-toggle {
	background-color: white;
	/* Set background color to white */
	color: #000000;
	/* Set icon color */
	padding: 5px;
	/* Adjust padding to ensure icon is centered */
	border: none;
	cursor: pointer;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* Center the icon */
	width: 30px;
	/* Set a fixed width for the button */
	height: 30px;
	/* Set a fixed height for the button */
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	/* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
	margin-left: 0;
	/* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
	transition: max-height 0.3s ease-out;
}

.hello {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	text-align: center;
	color: #2c3e50;
	margin-top: 60px;
}

.add-listing {
	margin-right: 10px;
	/* Add space between list items */
}

.add-listing a {
	border-radius: 8px;
	/* Optional: Add some border radius for better visual spacing */
	text-align: center;
	/* Center align the text and image */
	white-space: nowrap;
	/* Ensure text doesn't wrap to the next line */
}

.d-flex {
	flex-wrap: nowrap;
	/* Prevent items from wrapping onto the next line */
}

.bg-danger {
	background-color: #d9534f;
	/* Ensure consistent background color */
	color: white;
	/* Text color for contrast */
}

a img {
	vertical-align: middle;
	/* Align the images with text */
}

.input-with-icon select.form-control {
	padding-bottom: 1px;
	/* Adjust the value as needed */
}

.header.header-light {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1000;
	/* Ensures it stays on top of other elements */
	background-color: white;
	/* Keep the background color to avoid transparency issues */
}

#main-wrapper {
	padding-top: 70px;
	/* Adjust this based on your header's height */
}

/* Optional: Add a box-shadow to the fixed navigation for better visual appeal */
.header.header-light {
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
	display: inline-block;
	margin-left: 5px;
	transition: transform 0.3s;
}

.nav-menu li ul {
	display: none;
}

.nav-menu li ul.nav-submenu {
	display: block;
}

.nav-menu li.active>.submenu-indicator {
	transform: rotate(90deg);
}

.resp_log_thumb {
	margin-top: 100px;
	margin-left: 40px;
	width: 250px;
	height: 110px;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.form-check-label {
	margin-left: 10px;
}

.tagline {
	margin-top: 150px;
	margin-left: 50px;
}
</style>
