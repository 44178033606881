export default {
    header: {
        login: '登录',
        logout: '登出',
        wechat: '微信',
        whatsapp: 'WhatsApp',
        country: '国家',
        residential: '住宅',
        commercial: '商业',
        land: '土地',
        study_overseas: '海外留学',
        join_us: '加入我们',
        global_visa: '环球签证服务',
        greece: '希腊',
        malaysia: '马来西亚',
        profile: '个人资料',
        welcome_user: '欢迎用户',
        my_profile: '我的资料',
        my_dashboard: '我的仪表板',
        change_my_password: '更改我的密码',
        saved_property: '已保存的物业',
        singapore:'新加玻',
        add_property: '加房产广告',
    },
    change_password: {
        change_password: '更改密码',
        edit_change_password: '编辑或更改密码',
        dashboard: '仪表板',
        my_profile: '我的资料',
        saved_property: '已保存的房产',
        my_properties: '我的房产',
        my_subscription: '我的订阅',
        add_new_property: '添加新房产',
        change_password_label: '更改密码',
        change_your_password: '更改您的密码',
        old_password: '旧密码',
        new_password: '新密码',
        confirm_password: '确认密码',
        save_changes: '保存更改',
    }, 
    user_dashboard: {
        dashboard: '仪表板',
        welcome_message: '您好，欢迎回来',
        my_profile: '我的资料',
        saved_property: '已保存的房产',
        my_properties: '我的房产',
        my_subscription: '我的订阅',
        submit_new_property: '提交新房产',
        change_password: '更改密码',
        current_subscription: '您的当前订阅:',
        membership_type: '铂金会员',
        listed_property: '已列出房产',
        earned_this_week: '本周收入',
        earned_this_month: '本月收入',
        extra_area_chart: '额外区域图表',
        notifications: '通知',
        listing_status: '房产状态',
        title: '标题',
        listing_id: '房产编号',
        status: '状态',
        price: '价格',
        date_created: '创建日期',
    }, 
    user_profile: {
        my_profile: '我的资料',
        my_account_profile: '我的账户和资料',
        dashboard: '仪表板',
        saved_property: '已保存的房产',
        my_properties: '我的房产',
        my_subscription: '我的订阅',
        submit_new_property: '提交新房产',
        change_password: '更改密码',
        full_name: '您的全名',
        email: '电子邮件',
        title: '您的头衔',
        phone_number: '电话号码',
        address: '地址',
        city: '城市',
        state: '州/省',
        zipcode: '邮政编码',
        agency_name: '机构名称',
        agency_code: '机构代码',
        agent_code: '代理代码',
        property_outside_country: '您在海外有房产吗？',
        about_me: '关于我',
        social_accounts: '社交账户',
        facebook: '脸书',
        whatsapp: 'WhatsApp',
        instagram: 'Instagram',
        tiktok: '抖音',
        youtube: 'YouTube',
        save_changes: '保存更改',
        whatsapp_number: 'WhatsApp 号码',
        instagram_username: 'Instagram 代码',
    },       
    visa: {
        enquire_now: '立即咨询',
        global_visa_services: '全球签证服务',
        greece_header: '希腊黄金签证计划',
        greece: '希腊',
        greece_description: '仅需投资25万欧元即可获得永久居留权，享受欧盟福利，移民成本低，性价比高。',
        greece_title1: '性价比高',
        greece_text1: '为三代人投资（申请人、配偶和子女），无需包括父母。',
        greece_title2: '投资门槛低',
        greece_text2: '仅需25万欧元购置房产即可直接获得希腊永久居留权。',
        greece_title3: '无居住要求',
        greece_text3: '获得永久居留权后无需居住在希腊。',
        greece_title4: '申请要求简单',
        greece_text4: '无需进行体检。',
        greece_title5: '低房产税',
        greece_text5: '交易税为3%，年度房产税率为0.1-0.7%，相对其他欧洲国家较低。',
        greece_title6: '投资风险低',
        greece_text6: '房产市场价值几乎减半，经济正在复苏，房价有较大的增长空间。',
        greece_title7: '旅行自由',
        greece_text7: '希腊永久居民可免签前往26个申根国家。',
        spain: '西班牙',
        spain_header: '西班牙黄金签证计划',
        spain_description: '仅需50万欧元即可为全家快速、安全地获得黄金签证，自由选择房产，房源透明。',
        spain_title1: '为子女的未来',
        spain_text1: '确保为您的子女提供更好的机会。',
        spain_title2: '投资回报',
        spain_text2: '确保您的投资获得可观回报。',
        spain_title3: '一人申请，三代受益',
        spain_text3: '一人申请，三代受益。',
        spain_title4: '便利旅行',
        spain_text4: '享受27个欧洲申根国家的免签旅行。',
        spain_title5: '适合没有时间暂时出行的客户',
        spain_text5: '适合无法频繁出行的客户。',
        spain_title6: '申请要求',
        spain_text6_1: '1. 申请人必须年满18岁。',
        spain_text6_2: '2. 申请人必须无犯罪记录。',
        spain_text6_3: '3. 申请人必须在西班牙购买价值50万欧元或以上的房产（房产类型不限，包括公寓、联排别墅、独立房屋、商业房产等）。可以购买多处房产，只要总净值超过50万欧元。',
        portugal: '葡萄牙',
        portugal_header: '葡萄牙黄金签证计划',
        portugal_description: '通过投资50万欧元的基金获取葡萄牙黄金签证。',
        portugal_title1: '安全投资',
        portugal_text1: '投资于房产安全可靠，回报更稳定。',
        portugal_title2: '欧洲身份',
        portugal_text2: '直接进入欧洲，享受26个申根国家的免签旅行。',
        portugal_title3: '居留身份',
        portugal_text3: '黄金签证持有人可以在葡萄牙生活、工作和经商。',
        portugal_title4: '家庭受益',
        portugal_text4: '一个申请可包括三代家庭成员。',
        portugal_title5: '教育和医疗',
        portugal_text5: '持证人及其家庭可享受葡萄牙的免费公共教育和免费公共医疗。',
        portugal_title6: '无居住要求',
        portugal_text6: '每年仅需居住7天，5年后可轻松转换为永久居留权，无需搬迁。',
        portugal_title7: '税收负担低',
        portugal_text7: '葡萄牙没有遗产税，也没有全球税。',
        portugal_title8: '宜居环境',
        portugal_text8: '葡萄牙气候温和，阳光充足，生活成本低，英语普及率高，适合长期居住。',
        portugal_title9: '申请要求',
        portugal_text9: '1. 申请人必须年满18岁。2. 申请人必须无犯罪记录。3. 申请人必须投资50万欧元的基金。4. 申请人每两年内至少需在葡萄牙居住14天。',
        cyprus: '塞浦路斯',
        cyprus_header: '塞浦路斯黄金签证计划',
        cyprus_description: '通过投资50万欧元的基金获取葡萄牙黄金签证。',
        cyprus_title1: '父母与子女的福利',
        cyprus_text1: '包括三代人的福利。',
        cyprus_title2: '移民成本低',
        cyprus_text2: '整体费用低。',
        cyprus_title3: '无需连续居住',
        cyprus_text3: '无需在塞浦路斯连续居住。',
        cyprus_title4: '申请要求',
        cyprus_text4: '1. 主申请人必须年满18岁且无犯罪记录。' +
            '2. 在塞浦路斯购买最低价值为30万欧元的房产。' +
            '3. 年收入必须至少为5万欧元，配偶加1.5万欧元，每个孩子加1万欧元。' +
            '（例如：一个三口之家申请需要的年收入为5万欧元 + 1.5万欧元 + 1万欧元 = 7.5万欧元/年；工资、养老金、租金、股息、股票和其他收入来源均可接受。）',
        cyprus_title5: '随行家庭成员',
        cyprus_text5: '1. 配偶（包括事实婚姻配偶）。' +
            '2. 子女：未婚18岁以下子女，以及年龄在18-25岁之间正在全日制学习的未婚子女。',
        uk: '英国',
        uk_header: '英国创新创始人签证',
        uk_description: '通过建立一个创新、可行且可扩展的企业实现英国移民身份。',
        uk_title1: '快速获得英国居留权',
        uk_text1: '快速获得英国绿卡的途径。',
        uk_title2: '低投资要求',
        uk_text2: '最低投资额为50,000英镑。',
        uk_title3: '无需商业经验',
        uk_text3: '不需要之前的创业经验。',
        uk_title4: '家庭成员包含在内',
        uk_text4: '主申请人可以在申请中包括其配偶和18岁以下的子女。',
        uk_title5: '永久居留权',
        uk_text5: '主申请人可在3年后申请永久居留权，配偶和子女可在5年后申请。',
        uk_title6: '申请要求',
        uk_text6_1: '1. 提供雅思5.5分或以上的成绩。如果申请人拥有英国认可的以英语授课的学士学位或更高学位，可以免除雅思要求。',
        uk_text6_2: '2. 至少拥有50,000英镑的可用投资资金。',
        uk_text6_3: '3. 获批后，在英国成立的企业必须展示出创新性、可行性和可扩展性。',
        uk_text6_4: '4. 通过体检，无重大健康问题。',
        uk_title7: '随行家属',
        uk_text7: '配偶、民事伴侣或未婚伴侣以及18岁以下的受抚养子女（或当前在英国的18岁以上子女）。',
        uk_title8: '处理时间',
        uk_text8: '如果申请人在英国境外，获得英国创新者和创始人签证的平均处理时间为3周；如果申请人在英国境内，平均处理时间为8周。',
        italy: '意大利',
        italy_header: '意大利黄金签证计划',
        italy_description: '通过多种投资选择为非欧盟投资者提供意大利居留权，享有在欧盟生活、工作和学习的权利。',
        italy_title1: '无需语言考试',
        italy_title2: '无需在意大利长期居留',
        italy_title3: '在意大利生活、工作和学习。',
        italy_title4: '居住在意大利，享受气候、遗产和美食均排名第一的国家的福利。',
        italy_title5: '享受世界上最好的教育和医疗系统之一。',
        italy_title6: '对意大利境外的赠与、继承和利润免税。',
        italy_title7: '持有人享有申根区内的旅行自由。',
        italy_title8: '多种投资形式。',
        italy_text8_1: '至少200万欧元的政府债券。',
        italy_text8_2: '至少50万欧元的公司债券或股票。',
        italy_text8_3: '至少25万欧元的创新初创公司。',
        italy_title9: '申请要求',
        italy_text9_1: '申请人必须年满18岁。',
        italy_text9_2: '申请人必须无犯罪记录。',
        italy_text9_3: '申请人必须持有覆盖意大利所有风险的有效健康保险。',
        italy_text9_4: '申请人必须提供投资资金的合法来源证明。',
        italy_text9_5: '申请人必须符合最低居住要求以维持和更新居留许可。',
        italy_title10: '随行家庭成员',
        italy_text10_1: '合法婚姻配偶。',
        italy_text10_2: '18岁以下的未成年子女或成年子女在校且未婚的子女。',
        italy_text10_3: '如果符合特定年龄和依赖性要求，经济上依赖于投资者的父母也可以包括在内。',
    },
    listing: {
        most_rated: '最受好评',
        most_viewed: '最多浏览',
        new_listings: '最新房源',
        high_rated: '高评分',
        select_type: '选择房产类型',
        apartment: '公寓',
        condo: '公寓楼',
        family: '家庭',
        detached_house: '独立屋',
        landed_house: '有地住宅',
        villa: '别墅',
        less_than: '少于',
        above: '高于',
        select_price_range: '选择价格范围',
        select_room_type: '选择房间类型',
        select_bath_type: '选择浴室类型',
        find_new_home: '寻找新家',
        reset_filter: '重置筛选',
        beds: '房',
        one_beds: '一房',
        two_beds: '二房',
        three_beds: '三房',
        bath: '浴室',
        view_detail: '查看详情',
        max_area: '最大面积',
        min_area: '最小面积',
        max_price: '最高价格',
        min_price: '最低价格',
        location: '地点',
        enter_price: '输入价格',
        sort_by: '排序方式：',
        open_advance_filter: '打开筛选',
    },
    not_found: {
        back_to_home: '返回首页',
        not_found_text: '此页面不可用。请使用导航菜单浏览我们的房产，或返回首页。如需进一步帮助，请随时联系客户支持。'
    },
    banner: {
        home_banner_header: '寻找您的下一所房子',
        home_banner_text: '在您所在的城市找到新房和精选房产。',
        buy: '购买',
        rent: '租赁',
        country: '国家/城市/街道',
        greece: '希腊',
        malaysia: '马来西亚',
        singapore: '新加坡',
        property_type: '房产类型',
        apartment: '公寓',
        condominium: '共管公寓',
        landed_house: '独栋别墅',
        price_range: '价格范围',
        less_than: '低于',
        from: '从',
        to: '至',
        above: '高于',
    },
    home: {
        singapore_top_viewed: '新加坡热门房产',
        singapore_top_viewed_label: '发现新加坡最受欢迎的房产。探索黄金地段、豪华设施和专属住宅，定义狮城的都市生活。',
        contact_us: '联系方式',
        home_banner_citizenship: '投资入籍',
        home_banner_successful_apps: '成功申请',
        home_banner_positive_reviews: '好评',
        home_banner_global_visa: '全球签证',
        featured_property: '特色房产类型',
        featured_property_title: '寻找所有类型的房产',
        family_house: '家庭住宅',
        all_property: '寻找所有类型的房产',
        property: '房产',
        house_villa: '住宅和别墅',
        apartment: '公寓',
        office_studio: '办公室和工作室',
        villa_condo: '别墅和公寓',
        greece_featured: '希腊特色房产',
        greece_featured_text: '探索这座位于希腊海岸的豪华别墅，体验地中海奢华的极致。',
        beds: '卧室',
        bath: '浴室',
        malaysia_featured: '马来西亚待售房产',
        malaysia_featured_text: '体验现代奢华与文化丰富的完美结合，这座位于马来西亚的精致房产。',
        citizenship_investment: '投资入籍',
        citizenship_investment_text: '我们为全球所有投资入籍申请提供专家协助，提供定制的服务体验，并通过长期合作伙伴关系提供支持和维护。',
        listings: '房源',
        dominica: '多米尼克',
        dominica_title: '多米尼克投资入籍',
        dominica_description: '只需投资10万美元即可获得国籍，享受144个国家的免签证旅行，终生持有不可撤销的国籍。',
        find_out_more: '了解更多',
        north_macedonia: '北马其顿',
        north_macedonia_title: '北马其顿投资入籍计划',
        north_macedonia_description: '快速轻松地获得北马其顿国籍，投资起步价为20万欧元，允许双重国籍，财富保护',
        grenada: '格林纳达',
        grenada_title: '格林纳达投资入籍计划',
        grenada_description: '快速且安全地为全家获得格林纳达国籍，享受资本增值、礼物、财富和遗产的免税优惠。',
        top_property_places: '顶级房产地',
        top_property_description: '在全球范围内，探索无缝和安全的房产交易，希腊、马来西亚和新加坡的顶级房产可供选择。',
        greece: '希腊',
        malaysia: '马来西亚',
        singapore: '新加坡',
        properties: '房产',
        global_visa_services: '全球签证服务',
        global_visa_text: '我们为全球范围内的各类签证申请提供专家级协助，确保流程顺畅，量身定制以满足您的具体需求。',
        greece_visa_text: '希腊黄金签证计划',
        greece_visa_description: '只需投资25万欧元即可获得永久居留权，享受欧盟福利，低移民成本，高性价比。',
        spain_visa_text: '西班牙黄金签证计划',
        spain_visa_description: '只需投资50万欧元即可快速且安全地为全家获得黄金签证，自由选择房产，透明的房源列表。',
        portugal_visa_text: '葡萄牙黄金签证计划',
        portugal_visa_description: '快速且安全地在欧洲的金融中心为全家获得黄金签证，提供透明的房产选择。',
        uk_visa_text: '英国创新者签证',
        uk_visa_description: '通过建立创新、可行且可扩展的业务，并最低投资5万英镑，获得英国移民身份。',
        italy_visa_text: '意大利黄金签证计划',
        italy_visa_description: '为非欧盟投资者提供通过多种投资选项获得意大利居留的机会，享受居住、工作和学习在欧盟的多重好处。',
        us_visa_text: '美国E-2投资者居留签证',
        us_visa_description: '美国E-2投资者居留签证是一种非移民签证，允许外国投资者及其家人进入并在美国工作。',
        cyprus_visa_text: '塞浦路斯黄金签证计划',
        cyprus_visa_description: '只需投资30万欧元即可为全家获得永久居留权，无需持续居住要求，适用于年长父母和子女。',
        portugal: '葡萄牙',
        uk: '英国',
        spain: '西班牙',
        italy: '意大利',
        us: '美国',
        cyprus: '塞浦路斯',
    },
    property: {
        submit_enquiry: '提交查询',
        reviews: '评论',
        buy_new_property: '购买新房产',
        buy_second_hand_property: '购买二手房产',
        rent_property: '租赁房产',
        property: '物业',
        about_property: '关于物业',
        property_information: '物业信息',
        open_to_foreigner: '开放外国人购买',
        tenure: '租期',
        units: '单位',
        towers: '塔楼',
        completed_in: '竣工于',
        furnished: '家具齐全',
        free_car_park: '提供免费停车位',
        title: '产权',
        airbnb_management: 'Airbnb 管理',
        facilities: '设施',
        unit_layout: '单位',
        type: '类型',
        beds: '卧室',
        baths: '浴室',
        area: '面积',
        property_video: '物业视频',
        location: '位置',
        bikeable_score: '自行车通行评分',
        some_bike_infrastructure: '部分自行车基础设施',
        view_detail: '查看详情',
        walk_scores: '步行评分',
        most_errands_accomplished_on_foot: '大部分日常事务可步行完成',
        some_transit: '部分交通',
        nearby_public_transport_options: '附近有少量公共交通选择',
        nearby: '附近',
        schools: '学校',
        walkscore: '步行评分',
        hotel_restaurant: '酒店与餐厅',
        health_medical: '健康与医疗',
        want_to_know_more: '想了解更多',
        similar_property: '类似物业',
        rent: '出租',
        sale: '出售',
        sale_price: '售价',
        down_payment: '首付款',
        loan_term: '贷款期限',
        interest_rate: '利率 (%)',
        mortgage_calculator: '按揭计算器',
        monthly_payment: '每月还款',
        calculate: '计算',
        for: '用于',
    },
    citizenship: {
        citizenship_investment: '投资入籍',
        list_of_countries: '国家列表',
        citizenship_investment_text: '投资入籍计划为外国个人及其家人通过直接投资获得归化证书并取得公民身份提供了机会。',
        dominica: '多米尼克',
        north_macedonia: '北马其顿',
        grenada: '格林纳达',
        dominica_description: '多米尼克投资入籍计划由多米尼克联邦政府于1993年推出，允许外国投资者在无需居住要求或放弃现有国籍的情况下获得不可撤销的公民身份。',
        dominica_title1: '处理时间',
        dominica_text1: '获得多米尼克公民身份的平均处理时间为4个月。',
        dominica_title2: '免签国家',
        dominica_text2: '立即获得前往144个国家/地区的免签证旅行资格，包括申根国家、新加坡、香港和中国。',
        dominica_title3: '投资类型',
        dominica_text3: '捐赠给多米尼克政府基金或房地产投资。',
        dominica_title4: '投资费用',
        dominica_text4: '单个申请人需支付10万美元，四口之家需支付17.5万美元。',
        dominica_title5: '家庭受养人',
        dominica_text5: '配偶、30岁以下的子女、65岁以上的父母/祖父母。',
        dominica_title6: '护照有效期',
        dominica_text6: '16岁以上的人士护照有效期为10年，16岁以下的儿童护照有效期为5年。',
        dominica_title7: '良好的身份',
        dominica_text7: '多米尼克允许双重国籍，无任何限制。无需放弃当前国籍。',
        dominica_title8: '家庭福利',
        dominica_text8_1: '您和您的家人可以终生持有不可撤销的公民身份。此外，后代还可以通过血缘获得公民身份。',
        dominica_text8_2: '除了主申请人外，配偶、30岁以下的子女和65岁以上的父母/祖父母也可以获得多米尼克护照。',
        dominica_title9: '国际旅行福利',
        dominica_text9_1: '多米尼克共和国的声望护照可提供前往144个国家/地区的免签证通行权，包括英国和申根区。',
        dominica_text9_2: '多米尼克护照在英国延长居住权长达6个月，并且签证申请程序简便。',
        dominica_text9_3: '拥抱自由生活，并有权在多米尼克共和国和其他多个加勒比共同体成员国居住，时间不限。',
        dominica_title10: '税务和财务福利',
        dominica_text10_1: '无资本利得税、赠与税、财富税和遗产税；非居民无需纳税。',
        dominica_text10_2: '您可以在全球知名金融机构开设银行账户。',
        dominica_text10_3: '加勒比地区免关税贸易。',
        dominica_text10_4: '对利润回流和进口资本没有限制。',
        enquire_now: '立即咨询',
        north_macedonia_description: '北马其顿投资入籍计划使外国个人能够通过投资政府批准的基金来获得替代公民身份。北马其顿拥有欧洲最具吸引力和商业友好的投资入籍计划之一。但需注意，该计划仅接受全球1,000名申请人。北马其顿是北约成员国、欧盟成员国，并且是欧洲中心的一个完全民主共和国。',
        north_macedonia_title1: '处理时间',
        north_macedonia_text1: '获得北马其顿公民身份的平均处理时间为4个月。',
        north_macedonia_title2: '免签国家',
        north_macedonia_text2: '立即获得前往125个国家的免签证旅行资格，包括申根国家、中国和日本。',
        north_macedonia_title3: '投资类型',
        north_macedonia_text3: '投资于私人投资基金或直接投资于新设施。',
        north_macedonia_title4: '投资费用',
        north_macedonia_text4: '最低20万欧元。',
        north_macedonia_title5: '家庭受养人',
        north_macedonia_text5: '配偶和18岁以下的受抚养子女（配偶将在主申请人获得公民身份一年后获得公民身份，受抚养子女也将获得公民身份）。',
        north_macedonia_title6: '护照有效期',
        north_macedonia_text6: '北马其顿护照有效期为十（10）年。',
        north_macedonia_title7: '快速且简单',
        north_macedonia_text7: '无语言测试、经验或最低居住要求。',
        north_macedonia_title8: '自由与欧盟公民身份',
        north_macedonia_text8_1: '免签证前往包括欧盟、申根区、中国和日本在内的125个国家。',
        north_macedonia_text8_2: '欧盟成员候选国。',
        north_macedonia_text8_3: '允许双重国籍。',
        north_macedonia_title9: '财富保护',
        north_macedonia_text9_1: '固定货币为欧元。',
        north_macedonia_text9_2: '非居民零税收。',
        north_macedonia_text9_3: '制造业自由区内公司可100%持股，零税收。',
        grenada_description: '格林纳达投资入籍计划于2013年8月启动，为投资者提供多项福利，包括免签进入申根国家、英国和中国的资格，以及成为唯一一个与美国签署有条约的加勒比国家，格林纳达护照持有者可申请E-2美国“投资者签证”在美居住，无需居住在格林纳达或放弃当前国籍即可获得不可撤销的公民身份和护照。',
        grenada_title1: '处理时间',
        grenada_text1: '获得格林纳达公民身份的平均处理时间为5个月。',
        grenada_title2: '免签国家',
        grenada_text2: '立即获得前往145个国家的免签证旅行资格，包括申根国家、中国、英国、新加坡和香港。',
        grenada_title3: '投资类型',
        grenada_text3: '捐赠给格林纳达政府或投资于格林纳达政府批准的房地产项目。',
        grenada_title4: '投资费用',
        grenada_text4: '15万美元。',
        grenada_title5: '家庭受养人',
        grenada_text5: '配偶、30岁以下的子女、55岁以上的父母、18岁以上的兄弟姐妹。',
        grenada_title6: '护照有效期',
        grenada_text6: '格林纳达护照有效期为五（5）年。',
        grenada_title7: '良好的身份',
        grenada_text7_1: '格林纳达投资入籍为您提供终身不可撤销的公民身份。',
        grenada_text7_2: '后代也可以通过血缘获得公民身份。',
        grenada_text7_3: '格林纳达允许双重国籍，无任何限制，您可以保留当前国籍，无任何限制。',
        grenada_text7_4: '除主申请人外，配偶、30岁以下的子女、55岁以上的父母及18岁以上的兄弟姐妹也可受益于格林纳达公民身份。',
        grenada_title8: '良好信誉的护照',
        grenada_text8_1: '格林纳达护照持有人可免签进入145个国家，包括中国、申根国家、英国、新加坡、香港和澳大利亚。',
        grenada_text8_2: '生物特征电子护照，增强安全性和防伪措施。',
        grenada_text8_3: '与美国签订的商贸条约允许格林纳达公民申请E-2签证，赋予其在美国居住的权利。',
        grenada_title9: '税收优惠',
        grenada_text9_1: '格林纳达护照持有人享有资本利得税、赠与税、财富税和遗产税的豁免，非居民无需纳税。',
        grenada_text9_2: '格林纳达公民身份提供有吸引力的优惠，如企业税收优惠、完全免除进口关税、税收减免优惠和出口补贴。',
    },
    cta: {
        do_you_have_questions: '您有任何疑问？',
        help_to_find_home: '我们会帮助您找到您的下一个家。',
        contact_us_now: '立即联系我们',
    },
    footer: {
        footer: '环球房产投资',
        hotline: '热线',
        greece: '希腊',
        singapore: '新加坡',
        malaysia: '马来西亚',
        indonesia: '印度尼西亚',
        japan: '日本',
        germany: '德国',
        need_help: '您需要帮助？',
        offices: '公司地址',
        email_address: '电子邮件地址',
        subscribe: '订阅',
        services: '服务',
        buy_property: '购买物业',
        sell_property: '出售物业',
        property_for_rent: '物业出租',
        global_partners: '全球合作伙伴',
        study_overseas: '海外留学',
        global_visa: '全球签证',
        company: '公司',
        about: '关于',
        countries: '国家',
        property_listings: '物业列表',
        blog_news: '博客或新闻',
        our_team: '我们的团队',
        contact_us: '联系我们',
        email: '电子邮件',
    },
    modals: {
        scan_qr_to_connect: '扫描二维码连接',
        dream_home_anywhere: '帮助您在世界各地寻找您的梦想之家',
        login: '登录',
        register: '注册',
        username: '用户名',
        password: '密码',
        remember_me: '记得我',
        lost_password: '忘记密码？',
        forgot_password: '忘记密码',
        send_reset_email: '发送重置邮件',
        enter_email_address: '输入你的邮箱地址', 
        full_name: '全名',
        email: '电子邮件',
        phone_number: '电话号码',
        terms_conditions: '使用本网站即表示您接受条款和条件。',
        enter_phone_number: '输入您的电话号码',
        enter_email: '输入您的电子邮件',
        enter_full_name: '输入您的全名',
        enter_password: '输入您的密码',
        enter_username: '输入您的用户名',
    },
    form: {
        name: '姓名',
        phone_number: '电话号码',
        messages: '留言',
        get_latest_offer: '获取最新优惠',
    },
    settings: {
        language: '语言',
        currency: '货币',
        measurement: '测量',
        confirm: '确认',
    },
};
