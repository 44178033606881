<template>
    <div :key="$i18n.locale" class="yellow-skin">

        <!-- This is the preloader -->
        <!-- <div class="preloader"></div> -->
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->

            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
								<ol class="breadcrumb">
									<li class="breadcrumb-item active" aria-current="page">Add Property</li>
								</ol>
								<h2 class="breadcrumb-title">Submit Your Property</h2>
							</div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->
			
			<!-- ============================ User Dashboard ================================== -->
			<section class="gray pt-5 pb-5">
				<div class="container-fluid">
								
					<div class="row">
						
						<div class="col-lg-3 col-md-4">
							<div class="property_dashboard_navbar">
								
								<div class="dash_user_avater">
                                    <img :src="profileLogo" class="img-fluid avater" alt="">
                                    <h4>Globalahome User</h4>
                                    <span>Johor Bahru Malaysia</span>
                                </div>
								
								<div class="dash_user_menues">
									<ul>
										<li><router-link to="/user-dashboard"><i
                                                    class="fa fa-tachometer-alt"></i>Dashboard<span
                                                    class="notti_coun style-1">4</span></router-link></li>
                                        <li><router-link to="/user-profile"><i class="fa fa-user-tie"></i>My
                                            Profile</router-link></li>
                                        <li><router-link to="/user-saved-property"><i class="fa fa-bookmark"></i>Saved
                                            Property<span class="notti_coun style-2">7</span></router-link></li>
                                        <li><router-link to="/user-property"><i
                                            class="fa fa-tasks"></i>My Properties</router-link></li>
										<li><router-link to="/user-subscription"><i class="fa fa-gift"></i>My Subscription<span class="expiration">10 days left</span></router-link></li>
										<li class="active"><router-link to="/user-submit-property"><i class="fa fa-pen-nib"></i>Submit
                                            New Property</router-link></li>
										<li><router-link to="/user-change-password"><i
											class="fa fa-unlock-alt"></i>Change Password</router-link></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div class="col-lg-9 col-md-8">
							<div class="dashboard-body">
					
								<div class="dashboard-wraper">
									<div class="row">
										
										<!-- Submit Form -->
										<div class="col-lg-12 col-md-12">
										
											<div class="submit-page px-0">
												
												<!-- Basic Information -->
												<div class="frm_submit_block">	
													<h3>Basic Information</h3>
													<div class="frm_submit_wrap">
														<div class="form-row row">
														
															<div class="form-group col-md-12">
																<label>Property Title<a href="#" class="tip-topdata" data-tip="Property Title"><i class="ti-help"></i></a></label>
																<input type="text" class="form-control">
															</div>
															
															<div class="form-group col-md-6">
																<label>Status</label>
																<select id="status" class="form-control">
																	<option value="">&nbsp;</option>
																	<option value="1">For Rent</option>
																	<option value="2">For Sale</option>
																</select>
															</div>
															
															<div class="form-group col-md-6">
																<label>Property Type</label>
																<select id="ptypes" class="form-control">
																	<option value="">&nbsp;</option>
																	<option value="1">Houses</option>
																	<option value="2">Apartment</option>
																	<option value="3">Villas</option>
																	<option value="4">Commercial</option>
																	<option value="5">Offices</option>
																	<option value="6">Garage</option>
																</select>
															</div>
															
															<div class="form-group col-md-6">
																<label>Price</label>
																<input type="text" class="form-control" placeholder="USD">
															</div>
															
															<div class="form-group col-md-6">
																<label>Area</label>
																<input type="text" class="form-control">
															</div>
															
															<div class="form-group col-md-6">
																<label>Bedrooms</label>
																<select id="bedrooms" class="form-control">
																	<option value="">&nbsp;</option>
																	<option value="1">1</option>
																	<option value="2">2</option>
																	<option value="3">3</option>
																	<option value="4">4</option>
																	<option value="5">5</option>
																</select>
															</div>
															
															<div class="form-group col-md-6">
																<label>Bathrooms</label>
																<select id="bathrooms" class="form-control">
																	<option value="">&nbsp;</option>
																	<option value="1">1</option>
																	<option value="2">2</option>
																	<option value="3">3</option>
																	<option value="4">4</option>
																	<option value="5">5</option>
																</select>
															</div>
															
														</div>
													</div>
												</div>
												
												<!-- Gallery -->
												<div class="frm_submit_block">	
													<h3>Gallery</h3>
													<div class="frm_submit_wrap">
														<div class="form-row">
														
															<div class="form-group col-md-12">
																<label>Upload Gallery</label>
																<form action="https://shreethemes.net/upload-target" class="dropzone dz-clickable primary-dropzone">
																	<div class="dz-default dz-message">
																		<i class="ti-gallery"></i>
																		<span>Drag & Drop To Change Logo</span>
																	</div>
																</form>
															</div>
															
														</div>
													</div>
												</div>
												
												<!-- Location -->
												<div class="frm_submit_block">	
													<h3>Location</h3>
													<div class="frm_submit_wrap">
														<div class="form-row row">
														
															<div class="form-group col-md-6">
																<label>Address</label>
																<input type="text" class="form-control">
															</div>
															
															<div class="form-group col-md-6">
																<label>City</label>
																<input type="text" class="form-control">
															</div>
															
															<div class="form-group col-md-6">
																<label>State</label>
																<input type="text" class="form-control">
															</div>
															
															<div class="form-group col-md-6">
																<label>Zip Code</label>
																<input type="text" class="form-control">
															</div>
															
														</div>
													</div>
												</div>
												
												<!-- Detailed Information -->
												<div class="frm_submit_block">	
													<h3>Detailed Information</h3>
													<div class="frm_submit_wrap">
														<div class="form-row row">
														
															<div class="form-group col-md-12">
																<label>Description</label>
																<textarea class="form-control h-120"></textarea>
															</div>
															
															<div class="form-group col-md-4">
																<label>Building Age (optional)</label>
																<select id="bage" class="form-control">
																	<option value="">&nbsp;</option>
																	<option value="1">0 - 5 Years</option>
																	<option value="2">0 - 10Years</option>
																	<option value="3">0 - 15 Years</option>
																	<option value="4">0 - 20 Years</option>
																	<option value="5">20+ Years</option>
																</select>
															</div>
															
															<div class="form-group col-md-4">
																<label>Garage (optional)</label>
																<select id="garage" class="form-control">
																	<option value="">&nbsp;</option>
																	<option value="1">1</option>
																	<option value="2">2</option>
																	<option value="3">3</option>
																	<option value="4">4</option>
																	<option value="5">5</option>
																</select>
															</div>
															
															<div class="form-group col-md-4">
																<label>Rooms (optional)</label>
																<select id="rooms" class="form-control">
																	<option value="">&nbsp;</option>
																	<option value="1">1</option>
																	<option value="2">2</option>
																	<option value="3">3</option>
																	<option value="4">4</option>
																	<option value="5">5</option>
																</select>
															</div>
															
															<div class="form-group col-md-12">
																<label>Other Features (optional)</label>
																<div class="o-features">
																	<ul class="no-ul-list third-row">
																		<li>
																			<input id="a-1" class="form-check-input" name="a-1" type="checkbox">
																			<label for="a-1" class="form-check-label">Air Condition</label>
																		</li>
																		<li>
																			<input id="a-2" class="form-check-input" name="a-2" type="checkbox">
																			<label for="a-2" class="form-check-label">Bedding</label>
																		</li>
																		<li>
																			<input id="a-3" class="form-check-input" name="a-3" type="checkbox">
																			<label for="a-3" class="form-check-label">Heating</label>
																		</li>
																		<li>
																			<input id="a-4" class="form-check-input" name="a-4" type="checkbox">
																			<label for="a-4" class="form-check-label">Internet</label>
																		</li>
																		<li>
																			<input id="a-5" class="form-check-input" name="a-5" type="checkbox">
																			<label for="a-5" class="form-check-label">Microwave</label>
																		</li>
																		<li>
																			<input id="a-6" class="form-check-input" name="a-6" type="checkbox">
																			<label for="a-6" class="form-check-label">Smoking Allow</label>
																		</li>
																		<li>
																			<input id="a-7" class="form-check-input" name="a-7" type="checkbox">
																			<label for="a-7" class="form-check-label">Terrace</label>
																		</li>
																		<li>
																			<input id="a-8" class="form-check-input" name="a-8" type="checkbox">
																			<label for="a-8" class="form-check-label">Balcony</label>
																		</li>
																		<li>
																			<input id="a-9" class="form-check-input" name="a-9" type="checkbox">
																			<label for="a-9" class="form-check-label">Icon</label>
																		</li>
																		<li>
																			<input id="a-10" class="form-check-input" name="a-10" type="checkbox">
																			<label for="a-10" class="form-check-label">Wi-Fi</label>
																		</li>
																		<li>
																			<input id="a-11" class="form-check-input" name="a-11" type="checkbox">
																			<label for="a-11" class="form-check-label">Beach</label>
																		</li>
																		<li>
																			<input id="a-12" class="form-check-input" name="a-12" type="checkbox">
																			<label for="a-12" class="form-check-label">Parking</label>
																		</li>
																	</ul>
																</div>
															</div>
															
														</div>
													</div>
												</div>
												
												<!-- Contact Information -->
												<div class="frm_submit_block">	
													<h3>Contact Information</h3>
													<div class="frm_submit_wrap">
														<div class="form-row row">
														
															<div class="form-group col-md-4">
																<label>Name</label>
																<input type="text" class="form-control">
															</div>
															
															<div class="form-group col-md-4">
																<label>Email</label>
																<input type="text" class="form-control">
															</div>
															
															<div class="form-group col-md-4">
																<label>Phone (optional)</label>
																<input type="text" class="form-control">
															</div>
															
														</div>
													</div>
												</div>
												
												<div class="form-group col-lg-12 col-md-12">
													<label>GDPR Agreement *</label>
													<ul class="no-ul-list">
														<li>
															<input id="aj-1" class="form-check-input" name="aj-1" type="checkbox">
															<label for="aj-1" class="form-check-label">I consent to having this website store my submitted information so they can respond to my inquiry.</label>
														</li>
													</ul>
												</div>
												
												<div class="form-group col-lg-12 col-md-12">
													<button class="btn btn-danger" type="submit">Submit & Preview</button>
												</div>
															
											</div>
										</div>
										
									</div>
									
								</div>
								<!-- row -->
							
							</div>
						</div>
						
					</div>
				</div>
			</section>
			<!-- ============================ User Dashboard End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import { auth } from '../../firebase'; // Import the auth object
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
} from 'firebase/auth';

import 'magnific-popup';
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import greeceFlag from '@/assets/img/greece_flag.webp';
import spanyolFlag from '@/assets/img/spanyol_flag.webp';
import portugalFlag from '@/assets/img/portugal_flag.webp';
import ukFlag from '@/assets/img/united-kingdom.webp';
import italyFlag from '@/assets/img/italy.webp';
import usFlag from '@/assets/img/united-states.webp';
import cyprusFlag from '@/assets/img/cyprus_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';


export default {
    name: 'UserDashboard',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            forgotPasswordEmail: '',
            resetPasswordError: '',
            resetPasswordSuccess: '',
            selectedLanguage: this.$i18n.locale,
            phoneNumber: '',
            loginEmail: '',
            loginPassword: '',
            fullName: '',
            registerEmail: '',
            registerPassword: '',
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            greeceFlag,
            spanyolFlag,
            portugalFlag,
            ukFlag,
            italyFlag,
            usFlag,
            cyprusFlag,
            oldIcon,
            activeSubmenu: null,
            navMenuVisible: false,
            userLoggedIn: false,
            loginError: '',
        };
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
        async handleForgotPassword() {
            try {
                await sendPasswordResetEmail(auth, this.forgotPasswordEmail);
                this.resetPasswordSuccess = 'A password reset email has been sent to your email address.';
                this.resetPasswordError = '';
            } catch (error) {
                console.error('Error sending password reset email:', error.message);
                this.resetPasswordError = 'Failed to send password reset email. Please try again.';
                this.resetPasswordSuccess = '';
            }
        },
        async handleLogin() {
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    this.loginEmail,
                    this.loginPassword
                );
                // Handle successful login
                console.log('User logged in:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.loginError = '';
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error logging in:', error.message);
                this.loginError = 'Invalid username or password. Please try again.';
            }
        },
        async handleRegister() {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    this.registerEmail,
                    this.registerPassword
                );
                // Handle successful registration
                console.log('User registered:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error registering:', error.message);
                // Handle errors (e.g., show an error message)
            }
        },
        async handleLogout() {
            try {
                await signOut(auth);
                this.userLoggedIn = false; // Set login state to false
                console.log('User logged out');
            } catch (error) {
                console.error('Error logging out:', error.message);
            }
        },
        changeLanguage() {
            console.log("Language changing to:", this.selectedLanguage);
            this.$i18n.locale = this.selectedLanguage;
            localStorage.setItem('userLanguage', this.selectedLanguage);
            this.$forceUpdate();
        },

    },
    created() {
        // Check authentication state on component creation
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userLoggedIn = true; // User is signed in
            } else {
                this.userLoggedIn = false; // User is signed out
            }
        });
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) {
            this.selectedLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
        }
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
