<template>
    <div :key="$i18n.locale" class="yellow-skin">
        <div id="main-wrapper">
           <!-- Start Navigation -->
           <Navigation />
			<!-- ============================ End Navigation ================================== -->
            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <section class="image-cover faq-sec text-center"
                :style="{ backgroundImage: `url(${require('@/assets/img/bg.jpg')})` }" data-overlay="6">
                <div class="container">
                    <div class="row">

                        <div class="col-lg-12 col-md-12 position-relative z-1">
                            <h1 class="text-light">{{ $t('citizenship.citizenship_investment') }}</h1>
                            <!-- <div class="faq-search">
                                <form>
                                    <input name="search" class="form-control" placeholder="Search Your Query...">
                                    <button type="submit" class="theme-cl"> <i class="ti-search"></i> </button>
                                </form>
                            </div> -->
                        </div>

                    </div>
                </div>
            </section>
            <!-- ============================ Page Title End ================================== -->

            <!-- ================= Citizenship by Investment  ================= -->
            <section>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12 col-sm-12">

                            <!-- Single Basics List -->
                            <div class="faq_wrap">
                                <div class="faq_wrap_title">
                                    <div class="row justify-content-center">
                                        <div class="col-lg-10 col-md-12">
                                            <div class="sec-heading center">
                                                <h2>{{ $t('citizenship.list_of_countries') }}</h2>
                                                <p style="color: black;">{{
                                                    $t('citizenship.citizenship_investment_text') }}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-12 col-md-12 col-sm-12">
                                            <div class="custom-tab style-1" style="margin-left: 10px;">
                                                <ul class="nav nav-tabs pb-2 b-0" id="myTab" role="tablist">
                                                    <li class="nav-item">
                                                        <a class="nav-link active" id="Dominica-tab"
                                                            data-bs-toggle="tab" href="#Dominica" role="tab"
                                                            aria-controls="Dominica" aria-selected="true">{{
                                                                $t('citizenship.dominica') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="North-Macedonia-tab"
                                                            data-bs-toggle="tab" href="#north_macedonia" role="tab"
                                                            aria-controls="north_macedonia" aria-selected="false">{{
                                                                $t('citizenship.north_macedonia') }}</a>
                                                    </li>
                                                    <li class="nav-item">
                                                        <a class="nav-link" id="Grenada-tab" data-bs-toggle="tab"
                                                            href="#grenada" role="tab" aria-controls="grenada"
                                                            aria-selected="false">{{ $t('citizenship.grenada') }}</a>
                                                    </li>
                                                </ul>
                                                <div class="tab-content" id="myTabContent">
                                                    <!-- Dominica Tab -->
                                                    <div class="tab-pane fade show active" id="Dominica" role="tabpanel"
                                                        aria-labelledby="Dominica-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="dominica_flag" class="img-fluid mx-auto"
                                                                        alt="">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.dominica') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.dominica_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text7') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text8_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text8_2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text9_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text9_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text9_3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_title10') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text10_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text10_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text10_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.dominica_text10_4') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- North Macedonia Tab -->
                                                    <div class="tab-pane fade" id="north_macedonia" role="tabpanel"
                                                        aria-labelledby="North-Macedonia-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="north_macedonia"
                                                                        class="img-fluid mx-auto" alt="">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.north_macedonia') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.north_macedonia_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text7') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text8_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text8_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text8_3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text9_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text9_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.north_macedonia_text9_3') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Grenada Tab -->
                                                    <div class="tab-pane fade" id="grenada" role="tabpanel"
                                                        aria-labelledby="Grenada-tab">
                                                        <div class="pricing_wrap">
                                                            <div class="fr-grid-thumb">
                                                                <a href="#">
                                                                    <img :src="grenadaFlag" class="img-fluid mx-auto"
                                                                        alt="">
                                                                </a>
                                                            </div>
                                                            <div class="prt_head">
                                                                <h4>{{ $t('citizenship.grenada') }}</h4>
                                                                <p style="color: black;">{{
                                                                    $t('citizenship.grenada_description') }}</p>
                                                            </div>
                                                            <div class="prt_body">
                                                                <ul>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title1') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text1') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title2') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text2') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title3') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title4') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title5') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text5') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title6') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text6') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title7') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text7_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text7_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text7_3') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text7_4') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title8') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text8_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text8_2') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text8_3') }}</p>
                                                                    <li style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_title9') }}</li>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text9_1') }}</p>
                                                                    <p style="color: black; margin-left: 70px">{{
                                                                        $t('citizenship.grenada_text9_2') }}</p>
                                                                </ul>
                                                            </div>
                                                            <div class="prt_footer">
                                                                <a href="https://wa.link/fw20eo"
                                                                    class="btn choose_package">{{
                                                                        $t('citizenship.enquire_now') }}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- /row -->

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ================= Citizenship by Investment End ================= -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import { auth } from '../../firebase'; // Import the auth object
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
} from 'firebase/auth';

import 'magnific-popup';

import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import grenadaFlag from '@/assets/img/grenada_flag.webp';
import north_macedonia from '@/assets/img/north-macedonia.webp';
import dominica_flag from '@/assets/img/dominica_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';


export default {
    name: 'GlobalVisa',
    components: {
		Navigation,
        PageFooter,
	},
    props: ['glb_id'],
    data() {
        return {
            addProperty,
            menuWidget,
            profileLogo,
            forgotPasswordEmail: '',
            resetPasswordError: '',
            resetPasswordSuccess: '',
            selectedLanguage: this.$i18n.locale,
            phoneNumber: '',
            loginEmail: '',
            loginPassword: '',
            fullName: '',
            registerEmail: '',
            registerPassword: '',
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            grenadaFlag,
            north_macedonia,
            dominica_flag,
            oldIcon,
            navMenuVisible: false,
            activeSubmenu: null,
            userLoggedIn: false,
            loginError: '',
        };
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
        async handleLogin() {
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    this.loginEmail,
                    this.loginPassword
                );
                // Handle successful login
                console.log('User logged in:', userCredential.user);
                this.userLoggedIn = true;
                this.loginError = '';
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error logging in:', error.message);
                this.loginError = 'Invalid username or password. Please try again.';
            }
        },
        async handleRegister() {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    this.registerEmail,
                    this.registerPassword
                );
                // Handle successful registration
                console.log('User registered:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error registering:', error.message);
                // Handle errors (e.g., show an error message)
            }
        },
        async handleLogout() {
            try {
                await signOut(auth);
                this.userLoggedIn = false; // Set login state to false
                console.log('User logged out');
            } catch (error) {
                console.error('Error logging out:', error.message);
            }
        },
        changeLanguage() {
            console.log("Language changing to:", this.selectedLanguage);
            this.$i18n.locale = this.selectedLanguage;
            localStorage.setItem('userLanguage', this.selectedLanguage);
            this.$forceUpdate();
        },
        async handleForgotPassword() {
            try {
                await sendPasswordResetEmail(auth, this.forgotPasswordEmail);
                this.resetPasswordSuccess = 'A password reset email has been sent to your email address.';
                this.resetPasswordError = '';
            } catch (error) {
                console.error('Error sending password reset email:', error.message);
                this.resetPasswordError = 'Failed to send password reset email. Please try again.';
                this.resetPasswordSuccess = '';
            }
        },

    },
    created() {
        // Check authentication state on component creation
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userLoggedIn = true; // User is signed in
            } else {
                this.userLoggedIn = false; // User is signed out
            }
        });
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) {
            this.selectedLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
        }
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
	background-color: white;
	/* Set background color to white */
	color: #000000;
	/* Set icon color */
	/* padding: 5px; */
	/* Adjust padding to ensure icon is centered */
	border: none;
	cursor: pointer;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	/* Center the icon */
	width: 50px;
	/* Set a fixed width for the button */
	height: 50px;
	/* Set a fixed height for the button */
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	/* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left:40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 70px;
    margin-left: 50px;
}
</style>
