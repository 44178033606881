<template>
    <div :key="$i18n.locale" class="yellow-skin">

        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->

            <div class="clearfix"></div>
            <div></div>
            <section class="gray pt-4">
                <div class="container">
                    <div class="row m-0">
                        <div class="short_wraping">
                            <div class="row align-items-center">
                                <div className="col-lg-3 col-md-6 col-sm-12">
                                    <ul className="shorting_grid">
                                        <h6>
                                            <a href="#">
                                                <span class="ti-map-alt"></span>
                                                {{ filteredProperties.length > 0 ? filteredProperties[0].country : '' }}
                                            </a>
                                        </h6>
                                    </ul>
                                </div>
                                <div class="col-lg-6 col-md-12 col-sm-12 order-lg-2 order-md-3 elco_bor col-sm-12">
                                    <div class="shorting_pagination">
                                        <div class="shorting_pagination_laft">
                                            <h5>{{ shownPropertiesRange }}</h5>
                                        </div>
                                        <div class="shorting_pagination_right">
                                            <ul>
                                                <li v-for="(page, index) in totalPages" :key="index">
                                                    <a href="javascript:void(0);"
                                                        :class="{ active: currentPage === page }"
                                                        @click="paginate(page)">
                                                        {{ page }}
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 col-sm-12 order-lg-3 order-md-2 col-sm-6">
                                    <div class="shorting-right">
                                        <label>Short By:</label>
                                        <div class="dropdown show">
                                            <a class="btn btn-filter dropdown-toggle" href="#" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <span class="selection">Most Rated</span>
                                            </a>
                                            <div class="drp-select dropdown-menu">
                                                <a class="dropdown-item" href="JavaScript:Void(0);">Most Rated</a>
                                                <a class="dropdown-item" href="JavaScript:Void(0);">Most Viewed</a>
                                                <a class="dropdown-item" href="JavaScript:Void(0);">New Listings</a>
                                                <a class="dropdown-item" href="JavaScript:Void(0);">High Rated</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <!-- property Sidebar -->
                        <div class="col-lg-4 col-md-12 col-sm-12">
                            <div class="page-sidebar p-0">
                                <a class="filter_links" data-bs-toggle="collapse" href="#fltbox" role="button"
                                    aria-expanded="false" aria-controls="fltbox">Open Advance Filter<i
                                        class="fa fa-sliders-h ms-2"></i></a>
                                <div class="collapse" id="fltbox">
                                    <div class="sidebar-widgets p-4">
                                        <div class="form-group">
                                            <div class="input-with-icon">
                                                <input type="text" class="form-control" placeholder="Location"
                                                    v-model="filters.location">
                                                <i class="ti-location-pin"></i>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="simple-input">
                                                <select id="ptype" class="form-control" v-model="filters.propertyType">
                                                    <option value="" disabled selected>Select Property Type</option>
                                                    <option value="Apartment">Apartment</option>
                                                    <option value="Condo">Condo</option>
                                                    <option value="Family">Family</option>
                                                    <option value="Houses">Detached house</option>
                                                    <option value="Villa">Villa</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="simple-input">
                                                <select id="price" class="form-control" v-model="filters.priceRange">
                                                    <option value="" disabled selected>Select Price Range</option>
                                                    <option value="1">Less Than RM100K</option>
                                                    <option value="2">RM100K - RM200K</option>
                                                    <option value="3">RM200K - RM300K</option>
                                                    <option value="4">RM300K - RM400K</option>
                                                    <option value="5">Above RM500K</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="simple-input">
                                                <select id="bedrooms" class="form-control" v-model="filters.bedrooms">
                                                    <option value="" disabled selected>Select Room Type</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="simple-input">
                                                <select id="bathrooms" class="form-control" v-model="filters.bathrooms">
                                                    <option value="" disabled selected>Select Bathroom Type</option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                    <option value="3">3</option>
                                                    <option value="4">4</option>
                                                    <option value="5">5</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <div class="simple-input">
                                                        <input type="text" class="form-control" placeholder="Min Area"
                                                            v-model="filters.minArea">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <div class="simple-input">
                                                        <input type="text" class="form-control" placeholder="Max Area"
                                                            v-model="filters.maxArea">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <h6>Enter Price</h6>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <div class="simple-input">
                                                        <input type="text" class="form-control" placeholder="Min Price"
                                                            v-model="filters.minPrice">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-6">
                                                <div class="form-group">
                                                    <div class="simple-input">
                                                        <input type="text" class="form-control" placeholder="Max Price"
                                                            v-model="filters.maxPrice">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6 col-md-6 col-sm-12 pt-4">
                                                <button class="btn btn-danger full-width" @click="applyFilters">Find
                                                    New Home</button>
                                            </div>
                                            <div class="col-lg-6 col-md-6 col-sm-12 pt-4">
                                                <button class="btn btn-outline-danger full-width"
                                                    @click="resetFilters">Reset Filter</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-12 col-sm-12">
                            <div class="row justify-content-center g-4">
                                <div v-for="(property, index) in currentProperties" :key="index"
                                    class="col-lg-6 col-md-6 col-sm-12">
                                    <!-- Single Property -->
                                    <div class="property-listing property-2">

                                        <div class="listing-img-wrapper">
                                            <div class="_exlio_125">{{ property.category === 'Rent' ? 'For Rent' : 'For Sale' }}</div>
                                            <div class="list-img-slide">
                                                <div class="click">
                                                    <div v-for="(image, imgIndex) in property.image_link.slice(0, 3)"
                                                        :key="imgIndex">
                                                        <router-link
                                                            :to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }">
                                                            <img :src="image" class="img-fluid mx-auto" alt="" />
                                                        </router-link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="listing-detail-wrapper">
                                            <div class="listing-short-detail-wrap">
                                                <div class="_card_list_flex mb-2">
                                                    <div class="_card_flex_01">
                                                        <span class="_list_blickes _netork"
                                                            style="margin-right: 10px;">{{
                                                                property.property_type }}</span>
                                                        <span class="_list_blickes types">{{ property.city }} {{
                                                            property.country
                                                        }}</span>
                                                    </div>
                                                    <div class="_card_flex_last">
                                                        <div class="prt_saveed_12lk">
                                                            <label class="toggler toggler-danger"><input
                                                                    type="checkbox"><i class="ti-heart"></i></label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="_card_list_flex">
                                                    <div class="_card_flex_01">
                                                        <h4 class="listing-name verified">
                                                            <router-link
                                                                :to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }"
                                                                class="prt-link-detail">
                                                                {{ property.property_name }} {{ property.area_address }}
                                                            </router-link>
                                                        </h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="price-features-wrapper">
                                            <div class="list-fx-features">
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img :src="bedSvg" width="15"
                                                            alt="Globalahome Bedroom" /></div>
                                                    {{ property.bedroom }} Beds
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img :src="bathtubSvg" width="15"
                                                            alt="Globalahome Bathroom" /></div>
                                                    {{ property.bathroom }} Bath
                                                </div>
                                                <div class="listing-card-info-icon">
                                                    <div class="inc-fleat-icon"><img :src="moveSvg" width="15"
                                                            alt="Globalahome Property Surface Area" /></div>
                                                    {{ property.surface_area }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="listing-detail-footer">
                                            <div class="footer-first">
                                                <h6 class="listing-card-info-price text-danger mb-0 p-0">{{
                                                    property.currency }}{{
                                                        property.price }}
                                                </h6>
                                            </div>
                                            <div class="footer-flex">
                                                <router-link
                                                    :to="{ name: getRouteNameByGlbId(property.glb_id), params: { glb_id: property.glb_id } }"
                                                    class="prt-view bg-seegreen">View Detail
                                                </router-link>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <!-- End Single Property -->
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- ============================ All Property ================================== -->
            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->

        </div>
    </div>
</template>

<script>
import { auth } from '../../firebase'; // Import the auth object
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
} from 'firebase/auth';

import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';
import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import bannerHome from '@/assets/img/banner-home.webp';
import sampleImage1 from '@/assets/img/p-4.webp';
import sampleImage2 from '@/assets/img/p-5.webp';
import sampleImage3 from '@/assets/img/p-6.webp';
import bedSvg from '@/assets/img/bed.svg';
import bathtubSvg from '@/assets/img/bathtub.svg';
import moveSvg from '@/assets/img/move.svg';
import pinSvg from '@/assets/img/pin.svg';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';

import { db } from '../../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

export default {
    name: 'ListingMalaysia',
    props: ['glb_id'],
    components: {
        Navigation,
        PageFooter,
    },
    data() {
        return {
            addProperty,
            menuWidget,
            profileLogo,
            forgotPasswordEmail: '',
            resetPasswordError: '',
            resetPasswordSuccess: '',
            registerPassword: '',
            selectedLanguage: this.$i18n.locale,
            phoneNumber: '',
            loginEmail: '',
            loginPassword: '',
            fullName: '',
            registerEmail: '',
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            bannerHome,
            sampleImage1,
            sampleImage2,
            sampleImage3,
            bedSvg,
            bathtubSvg,
            moveSvg,
            pinSvg,
            activeSubmenu: null,
            navMenuVisible: false,
            userLoggedIn: false, // Add this line
            properties: [],
            loginError: '',
            filteredProperties: [],
            filters: {
                location: '',
                propertyType: '',
                priceRange: '',
                bedrooms: '',
                bathrooms: '',
                minArea: '',
                maxArea: '',
                minPrice: '',
                maxPrice: '',
            },
            homeFilters: {
                location: this.$route.query.location || '',
                propertyType: this.$route.query.propertyType || '',
                priceRange: this.$route.query.priceRange || '',
            },
            currentPage: 1,
            propertiesPerPage: 10,
            totalProperties: 0,
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.filteredProperties.length / this.propertiesPerPage);
        },
        shownPropertiesRange() {
            if (this.filteredProperties.length === 0) {
                return 'Showing 0 results';
            }
            const indexOfFirstProperty = (this.currentPage - 1) * this.propertiesPerPage + 1;
            const indexOfLastProperty = Math.min(indexOfFirstProperty + this.currentProperties.length - 1, this.filteredProperties.length);
            return `Showing ${indexOfFirstProperty}-${indexOfLastProperty} of ${this.filteredProperties.length} results`;
        },
        currentProperties() {
            const indexOfLastProperty = this.currentPage * this.propertiesPerPage;
            const indexOfFirstProperty = indexOfLastProperty - this.propertiesPerPage;
            const currentProps = this.filteredProperties.slice(indexOfFirstProperty, indexOfLastProperty);

            // Console log the current properties
            console.log("Current Properties:", currentProps);

            return currentProps;
        }
    },
    mounted() {
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userLoggedIn = true; // User is signed in
            } else {
                this.userLoggedIn = false; // User is signed out
            }
        });
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) {
            this.selectedLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
        }
    },
    async created() {
        await this.fetchProperties();
        this.applyHomeFilters(); // Apply home filters on component creation
    },

    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            // Toggle the submenu
            if (this.activeSubmenu === menu) {
                this.activeSubmenu = null;
            } else {
                this.activeSubmenu = menu;
            }
        },
        navigateTo(location) {
            this.$router.push({ path: '/property-listing', query: { location } });
        },
        async fetchProperties() {
            const q = query(
                collection(db, 'property_listings'),
                where('glb_id', '>=', 'GLB_MY_'),
                where('glb_id', '<', 'GLB_MY_\uf8ff')
            );
            const querySnapshot = await getDocs(q);
            this.properties = querySnapshot.docs.map(doc => doc.data());
            this.filteredProperties = this.properties; // Initialize filteredProperties with all Greece properties
            this.applyHomeFilters(); // Apply any additional filters that might come from the home page
        },
        async handleForgotPassword() {
            try {
                await sendPasswordResetEmail(auth, this.forgotPasswordEmail);
                this.resetPasswordSuccess = 'A password reset email has been sent to your email address.';
                this.resetPasswordError = '';
            } catch (error) {
                console.error('Error sending password reset email:', error.message);
                this.resetPasswordError = 'Failed to send password reset email. Please try again.';
                this.resetPasswordSuccess = '';
            }
        },
        applyHomeFilters() {
            const priceRanges = {
                "1": { min: 0, max: 100000 },
                "2": { min: 100000, max: 200000 },
                "3": { min: 200000, max: 300000 },
                "4": { min: 300000, max: 400000 },
                "5": { min: 400000, max: 500000 },
                "6": { min: 500000, max: 600000 },
                "7": { min: 600000, max: 700000 },
                "8": { min: 700000, max: 800000 },
                "9": { min: 800000, max: 900000 },
                "10": { min: 900000, max: 1000000 },
                "11": { min: 1000000, max: Infinity },  // For "Above 1,000,000"
            };

            this.filteredProperties = this.properties.filter(property => {
                return (
                    (!this.homeFilters.location || property.country.toLowerCase() === this.homeFilters.location.toLowerCase()) &&
                    (!this.homeFilters.propertyType || property.property_type.toLowerCase() === this.homeFilters.propertyType.toLowerCase()) &&
                    (!this.homeFilters.priceRange || (
                        property.price >= priceRanges[this.homeFilters.priceRange].min &&
                        property.price <= priceRanges[this.homeFilters.priceRange].max
                    ))
                );
            });
        },
        applyFilters() {
            this.filteredProperties = this.properties.filter(property => {
                return (
                    (!this.filters.location || property.area_address.toLowerCase().includes(this.filters.location.toLowerCase())) &&
                    (!this.filters.propertyType || property.property_type.toLowerCase() === this.filters.propertyType.toLowerCase()) &&
                    (!this.filters.priceRange || this.applyPriceRangeFilter(property.price, this.filters.priceRange)) &&
                    (!this.filters.bedrooms || property.bedroom === parseInt(this.filters.bedrooms)) &&
                    (!this.filters.bathrooms || property.bathroom === parseInt(this.filters.bathrooms)) &&
                    (!this.filters.minArea || parseFloat(property.surface_area) >= parseFloat(this.filters.minArea)) &&
                    (!this.filters.maxArea || parseFloat(property.surface_area) <= parseFloat(this.filters.maxArea)) &&
                    (!this.filters.minPrice || parseFloat(property.price) >= parseFloat(this.filters.minPrice)) &&
                    (!this.filters.maxPrice || parseFloat(property.price) <= parseFloat(this.filters.maxPrice))
                );
            });
            this.currentPage = 1;
        },
        resetFilters() {
            this.filters = {
                location: '',
                propertyType: '',
                priceRange: '',
                bedrooms: '',
                bathrooms: '',
                minArea: '',
                maxArea: '',
                minPrice: '',
                maxPrice: '',
            };
            this.filteredProperties = this.properties;
            this.currentPage = 1;
        },
        applyPriceRangeFilter(price, range) {
            const priceRanges = {
                "1": { min: 0, max: 100000 },
                "2": { min: 100000, max: 200000 },
                "3": { min: 200000, max: 300000 },
                "4": { min: 300000, max: 400000 },
                "5": { min: 400000, max: Infinity }, // Adjusted range for above 400K
            };
            return price >= priceRanges[range].min && price <= priceRanges[range].max;
        },
        paginate(page) {
            this.currentPage = page;
        },
        async handleLogin() {
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    this.loginEmail,
                    this.loginPassword
                );
                // Handle successful login
                console.log('User logged in:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.loginError = '';
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error logging in:', error.message);
                this.loginError = 'Invalid username or password. Please try again.';
            }
        },
        changeLanguage() {
            console.log("Language changing to:", this.selectedLanguage);
            this.$i18n.locale = this.selectedLanguage;
            localStorage.setItem('userLanguage', this.selectedLanguage);
            this.$forceUpdate();
        },
        async handleRegister() {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    this.registerEmail,
                    this.registerPassword
                );
                // Handle successful registration
                console.log('User registered:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error registering:', error.message);
                // Handle errors (e.g., show an error message)
            }
        },
        async handleLogout() {
            try {
                await signOut(auth);
                this.userLoggedIn = false; // Set login state to false
                console.log('User logged out');
            } catch (error) {
                console.error('Error logging out:', error.message);
            }
        },
        getRouteNameByGlbId(glb_id) {
            if (glb_id.startsWith('GLB_GR_')) {
                return 'GreeceListing';
            } else if (glb_id.startsWith('GLB_MY_SALE_NEW_')) {
                return 'MalaysiaListing';
            } else if (glb_id.startsWith('GLB_MY_SALE_SECOND_')) {
                return 'MalaysiaSubsaleListing';
            } else if (glb_id.startsWith('GLB_MY_RENT_')) {
                return 'MalaysiaRentListing';
            } else if (glb_id.startsWith('GLB_SG_SALE_NEW_')) {
                return 'SingaporeListing';
            } else if (glb_id.startsWith('GLB_SG_SALE_SECOND_')) {
                return 'SingaporeSubsaleListing';
            }else {
                return 'NotFound';
            }
        },
    },
    watch: {
        '$route.query': {
            immediate: true,
            handler() {
                this.applyHomeFilters();
            },
        },
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    padding: 5px;
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 30px;
    /* Set a fixed width for the button */
    height: 30px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

/* Add your styles here, keeping them similar to what was originally provided */
.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
