import { createRouter, createWebHistory } from "vue-router";
import Home from "@/components/Home.vue";
import PropertyListing from "@/components/PropertyListing.vue";
import PropertyDetail from "@/components/PropertyDetail.vue";
import GlobalVisa from "@/components/GlobalVisa.vue";
import Carousel from "@/components/Carousel.vue";
import NotFound from "@/components/NotFound.vue";
import MalaysiaListing from "@/components/MalaysiaListing.vue";
import MalaysiaRentListing from "@/components/MalaysiaRentListing.vue";
import MalaysiaSubsaleListing from "@/components/MalaysiaSubsaleListing.vue";
import GreeceListing from "@/components/GreeceListing.vue";
import SingaporeListing from "@/components/SingaporeListing.vue";
import SingaporeRentListing from "@/components/SingaporeRentListing.vue";
import SingaporeSubsaleListing from "@/components/SingaporeSubsaleListing.vue";
import CitizenshipByInvestment from "@/components/CitizenshipByInvestment.vue";
import SubmitPropertyForm from "@/components/SubmitPropertyForm.vue";
import PropertySubmission from "@/components/PropertySubmission.vue";
import ListingGreece from "@/components/ListingGreece.vue";
import ListingMalaysia from "@/components/ListingMalaysia.vue";
import ListingSingapore from "@/components/ListingSingapore.vue";
import UserDashboard from "@/components/UserDashboard.vue";
import UserProfile from "@/components/UserProfile.vue";
import UserProperty from "@/components/UserProperty.vue";
import UserSavedProperty from "@/components/UserSavedProperty.vue";
import UserSubscription from "@/components/UserSubscription.vue";
import UserSubmitProperty from "@/components/UserSubmitProperty.vue";
import UserChangePassword from "@/components/UserChangePassword.vue";
import ImageUpload from "@/components/ImageUpload.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/img-upload",
    name: "ImageUpload",
    component: ImageUpload,
  },
  {
    path: "/greece-listing",
    name: "ListingGreece",
    component: ListingGreece,
  },
  {
    path: "/malaysia-listing",
    name: "ListingMalaysia",
    component: ListingMalaysia,
  },
  {
    path: "/singapore-listing",
    name: "ListingSingapore",
    component: ListingSingapore,
  },
  {
    path: "/property-listing",
    name: "PropertyListing",
    component: PropertyListing,
  },
  {
    path: "/submit-property-form",
    name: "PropertySubmission",
    component: PropertySubmission,
  },
  {
    path: "/property-detail/:glb_id",
    name: "PropertyDetail",
    component: PropertyDetail,
    props: true, 
  },
  {
    path: "/malaysia-property-detail/:glb_id",
    name: "MalaysiaListing",
    component: MalaysiaListing,
    props: true, 
  },
  {
    path: "/malaysia-rent-property/:glb_id",
    name: "MalaysiaRentListing",
    component: MalaysiaRentListing,
    props: true, 
  },
  {
    path: "/malaysia-subsale-property/:glb_id",
    name: "MalaysiaSubsaleListing",
    component: MalaysiaSubsaleListing,
    props: true, 
  },
  {
    path: "/greece-property-detail/:glb_id",
    name: "GreeceListing",
    component: GreeceListing,
    props: true, 
  },
  {
    path: "/singapore-property-detail/:glb_id",
    name: "SingaporeListing",
    component: SingaporeListing,
    props: true, 
  },
  {
    path: "/singapore-rent-detail/:glb_id",
    name: "SingaporeRentListing",
    component: SingaporeRentListing,
    props: true, 
  },
  {
    path: "/singapore-subsale-property/:glb_id",
    name: "SingaporeSubsaleListing",
    component: SingaporeSubsaleListing,
    props: true, 
  },
  {
    path: "/property-submission",
    name: "SubmitPropertyForm",
    component: SubmitPropertyForm,
    props: true, 
  },
  {
    path: "/global-visa",
    name: "GlobalVisa",
    component: GlobalVisa,
  },
  {
    path: "/carousel",
    name: "Carousel",
    component: Carousel,
  },
  {
    path: "/citizenship-by-investment",
    name: "CitizenshipByInvestment",
    component: CitizenshipByInvestment,
  },
  {
    path: "/user-property",
    name: "UserProperty",
    component: UserProperty,
  },
  {
    path: "/user-dashboard",
    name: "UserDashboard",
    component: UserDashboard,
  },
  {
    path: "/user-submit-property",
    name: "UserSubmitProperty",
    component: UserSubmitProperty,
  },
  {
    path: "/user-change-password",
    name: "UserChangePassword",
    component: UserChangePassword,
  },
  {
    path: "/user-subscription",
    name: "UserSubscription",
    component: UserSubscription,
  },
  {
    path: "/user-saved-property",
    name: "UserSavedProperty",
    component: UserSavedProperty,
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: UserProfile,
  },
  {
    path: "/not-found",
    name: "NotFound",
    component: NotFound,
  },
  // Catch-all route for undefined paths
  {
    path: "/:catchAll(.*)", // This matches any route that is not defined above
    name: "NotFound",
    component: NotFound,
  },
  // Add more routes as needed
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
