import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCn2ezRAnZ6cjdEYTNLSqUu8xje6s11_Mw",
  authDomain: "globalhome-c6c4b.firebaseapp.com",
  projectId: "globalhome-c6c4b",
  storageBucket: "globalhome-c6c4b.appspot.com",
  messagingSenderId: "97551556977",
  appId: "1:97551556977:web:8a21c465c37b9c614316d0"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const auth = getAuth(app);

export { db, auth, storage };