<template>
    <div :key="$i18n.locale" class="yellow-skin">

        <!-- This is the preloader -->
        <!-- <div class="preloader"></div> -->
        <div id="main-wrapper">
            <!-- Start Navigation -->
            <Navigation />
            <!-- ============================ End Navigation ================================== -->

            <div class="clearfix"></div>
            <!-- ============================ Page Title Start================================== -->
            <div class="page-title" :style="{ background: `#f4f4f4 url(${bgImage})` }" data-overlay="5">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">

                            <div class="breadcrumbs-wrap position-relative z-1">
								<ol class="breadcrumb">
									<li class="breadcrumb-item active" aria-current="page">{{ $t('user_profile.my_profile') }}</li>
								</ol>
								<h2 class="breadcrumb-title">{{ $t('user_profile.my_account_profile') }}</h2>
							</div>

                        </div>
                    </div>
                </div>
            </div>
            <!-- ============================ Page Title End ================================== -->
			
			<!-- ============================ User Dashboard ================================== -->
			<section class="gray pt-5 pb-5">
				<div class="container-fluid">
								
					<div class="row">
						
						<div class="col-lg-3 col-md-4 col-sm-12">
							<div class="property_dashboard_navbar">
								
                                <div class="dash_user_avater">
                                    <img :src="profileLogo" class="img-fluid avater" alt="">
                                    <h4>Globalahome User</h4>
                                    <span>Johor Bahru Malaysia</span>
                                </div>
								
								<div class="dash_user_menues">
									<ul>
										<li><router-link to="/user-dashboard"><i
                                                    class="fa fa-tachometer-alt"></i>{{ $t('user_profile.dashboard') }}<span
                                                    class="notti_coun style-1">4</span></router-link></li>
										<li class="active"><router-link to="/user-profile"><i class="fa fa-user-tie"></i>{{ $t('user_profile.my_profile') }}</router-link></li>
                                        <li><router-link to="/user-saved-property"><i class="fa fa-bookmark"></i>{{ $t('user_profile.saved_property') }}<span class="notti_coun style-2">7</span></router-link></li>
                                        <li><router-link to="/user-property"><i class="fa fa-tasks"></i>{{ $t('user_profile.my_properties') }}</router-link></li>
                                        <!-- <li><a href="messages.html"><i class="fa fa-envelope"></i>Messages<span
                                                    class="notti_coun style-3">3</span></a></li> -->
                                        <li><router-link to="/user-subscription"><i class="fa fa-gift"></i>{{ $t('user_profile.my_subscription') }}<span class="expiration">10 days left</span></router-link></li>
                                        <li><router-link to="/user-submit-property"><i class="fa fa-pen-nib"></i>{{ $t('user_profile.submit_new_property') }}</router-link></li>
                                        <li><router-link to="/user-change-password"><i
                                            class="fa fa-unlock-alt"></i>{{ $t('user_profile.change_password') }}</router-link></li>
									</ul>
								</div>
							</div>
						</div>
						
						<div class="col-lg-9 col-md-8 col-sm-12">
							<div class="dashboard-body">
							
								<div class="dashboard-wraper">
								
									<!-- Basic Information -->
									<div class="frm_submit_block">	
										<h4>My Account</h4>
										<div class="frm_submit_wrap">
											<div class="form-row row">
											
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.full_name') }}</label>
													<input type="text" class="form-control" value="Globalahome User">
												</div>
												
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.email') }}</label>
													<input type="email" class="form-control" value="globalahome@gmail.com">
												</div>
												
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.title') }}</label>
													<input type="text" class="form-control" value="">
												</div>
												
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.phone_number') }}</label>
													<input type="text" class="form-control" value="">
												</div>
												
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.address') }}</label>
													<input type="text" class="form-control" value="">
												</div>
												
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.city') }}</label>
													<input type="text" class="form-control" value="">
												</div>
												
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.state') }}</label>
													<input type="text" class="form-control" value="">
												</div>
												
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.zipcode') }}</label>
													<input type="text" class="form-control" value="">
												</div>

                                                <div class="form-group col-md-6">
													<label>{{ $t('user_profile.agency_name') }}</label>
													<input type="text" class="form-control" value="">
												</div>

                                                <div class="form-group col-md-6">
													<label>{{ $t('user_profile.agency_code') }}</label>
													<input type="text" class="form-control" value="">
												</div>

                                                <div class="form-group col-md-6">
													<label>{{ $t('user_profile.agent_code') }}</label>
													<input type="text" class="form-control" value="">
												</div>

                                                <div class="form-group col-md-6">
													<label>{{ $t('user_profile.property_outside_country') }}</label>
													<input type="text" class="form-control" value="">
												</div>
												
												<div class="form-group col-md-12">
													<label>{{ $t('user_profile.about_me') }}</label>
													<textarea class="form-control"></textarea>
												</div>
												
											</div>
										</div>
									</div>
									
									<div class="frm_submit_block">	
										<h4>{{ $t('user_profile.social_accounts') }}</h4>
										<div class="frm_submit_wrap">
											<div class="form-row row">
											
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.facebook') }}</label>
													<input type="text" class="form-control" value="https://facebook.com/">
												</div>
												
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.whatsapp') }}</label>
													<input type="text" class="form-control" :placeholder="$t('user_profile.whatsapp_number')">
												</div>
												
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.instagram') }}</label>
													<input type="text" class="form-control" :placeholder="$t('user_profile.instagram_username')">
												</div>
												
												<div class="form-group col-md-6">
													<label>{{ $t('user_profile.tiktok') }}</label>
													<input type="text" class="form-control" value="Tiktok Link">
												</div>

                                                <div class="form-group col-md-6">
													<label>{{ $t('user_profile.youtube') }}</label>
													<input type="text" class="form-control" value="Youtube Channel Link">
												</div>
												
												<div class="form-group col-lg-12 col-md-12 mt-4">
													<button class="btn btn-danger btn-lg" type="submit">{{ $t('user_profile.save_changes') }}</button>
												</div>
												
											</div>
										</div>
									</div>
									
								</div>
							
							</div>
						</div>
						
					</div>
				</div>
			</section>
			<!-- ============================ User Dashboard End ================================== -->

            <!-- ============================ Call To Action ================================== -->
            <PageFooter />
            <!-- ============================ Call To Action End ================================== -->
            <!-- ============================ Footer Start ================================== -->
            <!-- ============================ Footer End ================================== -->
        </div>
    </div>
</template>

<script>
import { auth } from '../../firebase'; // Import the auth object
import {
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    sendPasswordResetEmail,
} from 'firebase/auth';

import 'magnific-popup';
import Navigation from '@/components/Navigation.vue';
import PageFooter from '@/components/PageFooter.vue';

import globalHomeLogo from '@/assets/img/global_home.png';
import whatsappLogo from '@/assets/img/whatsapp.png';
import wechatLogo from '@/assets/img/wechat.png';
import loginLogo from '@/assets/img/lock.png';
import settingsLogo from '@/assets/img/settings.png';
import qrCodeLogo from '@/assets/img/baizupo_qr.jpeg';
import sampleImage1 from '@/assets/img/p-4.webp';
import oldIcon from '@/assets/img/old.png';
import greeceFlag from '@/assets/img/greece_flag.webp';
import spanyolFlag from '@/assets/img/spanyol_flag.webp';
import portugalFlag from '@/assets/img/portugal_flag.webp';
import ukFlag from '@/assets/img/united-kingdom.webp';
import italyFlag from '@/assets/img/italy.webp';
import usFlag from '@/assets/img/united-states.webp';
import cyprusFlag from '@/assets/img/cyprus_flag.webp';
import profileLogo from '@/assets/img/profile.png';
import menuWidget from '@/assets/img/grid-lines.png';
import addProperty from '@/assets/img/add.png';
import bgImage from '@/assets/img/bg.jpg';


export default {
    name: 'UserDashboard',
    components: {
        Navigation,
        PageFooter,
    },
    props: ['glb_id'],
    data() {
        return {
            bgImage,
            addProperty,
            menuWidget,
            profileLogo,
            forgotPasswordEmail: '',
            resetPasswordError: '',
            resetPasswordSuccess: '',
            selectedLanguage: this.$i18n.locale,
            phoneNumber: '',
            loginEmail: '',
            loginPassword: '',
            fullName: '',
            registerEmail: '',
            registerPassword: '',
            globalHomeLogo,
            whatsappLogo,
            wechatLogo,
            loginLogo,
            settingsLogo,
            qrCodeLogo,
            sampleImage1,
            greeceFlag,
            spanyolFlag,
            portugalFlag,
            ukFlag,
            italyFlag,
            usFlag,
            cyprusFlag,
            oldIcon,
            activeSubmenu: null,
            navMenuVisible: false,
            userLoggedIn: false,
            loginError: '',
        };
    },
    methods: {
        toggleNavMenu() {
            this.navMenuVisible = !this.navMenuVisible;
        },
        toggleSubmenu(menu) {
            this.activeSubmenu = this.activeSubmenu === menu ? null : menu;
        },
        async handleForgotPassword() {
            try {
                await sendPasswordResetEmail(auth, this.forgotPasswordEmail);
                this.resetPasswordSuccess = 'A password reset email has been sent to your email address.';
                this.resetPasswordError = '';
            } catch (error) {
                console.error('Error sending password reset email:', error.message);
                this.resetPasswordError = 'Failed to send password reset email. Please try again.';
                this.resetPasswordSuccess = '';
            }
        },
        async handleLogin() {
            try {
                const userCredential = await signInWithEmailAndPassword(
                    auth,
                    this.loginEmail,
                    this.loginPassword
                );
                // Handle successful login
                console.log('User logged in:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.loginError = '';
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error logging in:', error.message);
                this.loginError = 'Invalid username or password. Please try again.';
            }
        },
        async handleRegister() {
            try {
                const userCredential = await createUserWithEmailAndPassword(
                    auth,
                    this.registerEmail,
                    this.registerPassword
                );
                // Handle successful registration
                console.log('User registered:', userCredential.user);
                this.userLoggedIn = true; // Set login state to true
                this.$refs.closeBtn.click();
            } catch (error) {
                console.error('Error registering:', error.message);
                // Handle errors (e.g., show an error message)
            }
        },
        async handleLogout() {
            try {
                await signOut(auth);
                this.userLoggedIn = false; // Set login state to false
                console.log('User logged out');
            } catch (error) {
                console.error('Error logging out:', error.message);
            }
        },
        changeLanguage() {
            console.log("Language changing to:", this.selectedLanguage);
            this.$i18n.locale = this.selectedLanguage;
            localStorage.setItem('userLanguage', this.selectedLanguage);
            this.$forceUpdate();
        },

    },
    created() {
        // Check authentication state on component creation
        onAuthStateChanged(auth, (user) => {
            if (user) {
                this.userLoggedIn = true; // User is signed in
            } else {
                this.userLoggedIn = false; // User is signed out
            }
        });
        const savedLanguage = localStorage.getItem('userLanguage');
        if (savedLanguage) {
            this.selectedLanguage = savedLanguage;
            this.$i18n.locale = savedLanguage;
        }
    },
};
</script>

<style scoped>
.nav-container {
    margin-bottom: 0px;
    display: flex;
    justify-content: flex-end;
    /* Aligns the button to the right */
}

.nav-dropdown-toggle {
    background-color: white;
    /* Set background color to white */
    color: #000000;
    /* Set icon color */
    /* padding: 5px; */
    /* Adjust padding to ensure icon is centered */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* Center the icon */
    width: 50px;
    /* Set a fixed width for the button */
    height: 50px;
    /* Set a fixed height for the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    /* Optional: add a subtle shadow */
}

.nav-dropdown-toggle i {
    margin-left: 0;
    /* Remove any left margin */
}

/* Optional: add transition to make it smooth */
.nav-menu {
    transition: max-height 0.3s ease-out;
}

.input-with-icon select.form-control {
    padding-bottom: 1px;
    /* Adjust the value as needed */
}

.hello {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
}

.add-listing {
    margin-right: 10px;
}

.add-listing a {
    border-radius: 8px;
    text-align: center;
    white-space: nowrap;
}

.d-flex {
    flex-wrap: nowrap;
}

.bg-danger {
    background-color: #d9534f;
    color: white;
}

a img {
    vertical-align: middle;
}

.header.header-light {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
}

#main-wrapper {
    padding-top: 70px;
}

.header.header-light {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.submenu-indicator {
    display: inline-block;
    margin-left: 5px;
    transition: transform 0.3s;
}

.nav-menu li ul {
    display: none;
}

.nav-menu li ul.nav-submenu {
    display: block;
}

.nav-menu li.active>.submenu-indicator {
    transform: rotate(90deg);
}

.resp_log_thumb {
    margin-top: 100px;
    margin-left: 40px;
    width: 250px;
    height: 110px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.form-check-label {
    margin-left: 10px;
}

.tagline {
    margin-top: 150px;
    margin-left: 50px;
}
</style>
