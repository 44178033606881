export default {
    header: {
        login: 'Login',
        logout: 'Logout',
        wechat: 'Wechat',
        whatsapp: 'Whatsapp',
        country: 'Country',
        residential: 'Residential',
        commercial: 'Commercial',
        land: 'Land',
        study_overseas: 'Study Overseas',
        join_us: 'Join us',
        global_visa: 'Global Visa',
        greece: 'Greece',
        malaysia: 'Malaysia',
        singapore:'Singapore',
        profile: 'Profile',
        welcome_user: 'Welcome User',
        my_profile: 'My Profile',
        my_dashboard: 'My Dashboard',
        change_my_password: 'Change My Password',
        saved_property: 'Saved Property',
        add_property: 'Add Property',
    },
    user_dashboard: {
        dashboard: 'Dashboard',
        welcome_message: 'Hello User, Welcome',
        my_profile: 'My Profile',
        saved_property: 'Saved Property',
        my_properties: 'My Properties',
        my_subscription: 'My Subscription',
        submit_new_property: 'Submit New Property',
        change_password: 'Change Password',
        current_subscription: 'Your Current Subscription:',
        membership_type: 'Platinum Membership',
        listed_property: 'Listed Property',
        earned_this_week: 'Earned This Week',
        earned_this_month: 'Earned This Month',
        extra_area_chart: 'Extra Area Chart',
        notifications: 'Notifications',
        listing_status: 'Listing Status',
        title: 'Title',
        listing_id: 'Listing ID',
        status: 'Status',
        price: 'Price',
        date_created: 'Date Created',
    },    
    user_profile: {
        my_profile: 'My Profile',
        my_account_profile: 'My Account & Profile',
        dashboard: 'Dashboard',
        saved_property: 'Saved Property',
        my_properties: 'My Properties',
        my_subscription: 'My Subscription',
        submit_new_property: 'Submit New Property',
        change_password: 'Change Password',
        full_name: 'Your Full Name',
        email: 'Email',
        title: 'Your Title',
        phone_number: 'Phone Number',
        address: 'Address',
        city: 'City',
        state: 'State',
        zipcode: 'Zipcode',
        agency_name: 'Agency Name',
        agency_code: 'Agency Code',
        agent_code: 'Agent Code',
        property_outside_country: 'Do you have property outside of your home country?',
        about_me: 'About Me',
        social_accounts: 'Social Accounts',
        facebook: 'Facebook',
        whatsapp: 'Whatsapp',
        instagram: 'Instagram',
        tiktok: 'Tiktok',
        youtube: 'Youtube',
        save_changes: 'Save Changes',
        whatsapp_number: 'WhatsApp Number',
        instagram_username: 'Instagram Username',
    }, 
    change_password:{
        change_password: 'Change Password',
        edit_change_password: 'Edit or Change Password',
        dashboard: 'Dashboard',
        my_profile: 'My Profile',
        saved_property: 'Saved Property',
        my_properties: 'My Properties',
        my_subscription: 'My Subscription',
        add_new_property: 'Add New Property',
        change_password_label: 'Change Password',
        change_your_password: 'Change Your Password',
        old_password: 'Old Password',
        new_password: 'New Password',
        confirm_password: 'Confirm Password',
        save_changes: 'Save Changes',
    },
    not_found: {
        back_to_home: 'Back To Home',
        not_found_text: 'This page is not available. Use the navigation menu to explore our properties or return to the homepage. For further assistance, feel free to reach out to our customer support.'
    },
    banner: {
        home_banner_header: 'Search Your Next Home',
        home_banner_text: 'Find new & featured property located in your local city.',
        buy: 'Buy',
        rent: 'Rent',
        country: 'Country/City/Street',
        greece: 'Greece',
        malaysia: 'Malaysia',
        singapore: 'Singapore',
        property_type: 'Property Type',
        apartment: 'Apartment',
        condominium: 'Condominium',
        landed_house: 'Landed house',
        price_range: 'Price Range',
        less_than: 'Less than',
        from: 'From',
        to: 'To',
        above: 'Above',
    },
    listing: {
        most_rated: 'Most Rated',
        most_viewed: 'Most Viewed',
        new_listings: 'New Listings',
        high_rated: 'High Rated',
        select_type: 'Select Property Type',
        apartment: 'Apartment',
        condo: 'Condo',
        family: 'Family',
        detached_house: 'Detached House',
        landed_house: 'Landed House',
        villa: 'Villa',
        less_than: 'Less Than',
        above: 'Above',
        select_price_range: 'Select Price Range',
        select_room_type: 'Select Room Type',
        select_bath_type: 'Select Bathroom Type',
        find_new_home: 'Find New Home',
        reset_filter: 'Reset Filter',
        beds: 'Beds',
        one_beds: '1 Bed',
        two_beds: '2 Beds',
        three_beds: '3 Beds',
        bath: 'Bath',
        view_detail: 'View Detail',
        max_area: 'Max Area',
        min_area: 'Min Area',
        max_price: 'Max Price',
        min_price: 'Min Price',
        location: 'Location',
        enter_price: 'Enter Price',
        sort_by: 'Sort By :',
        open_advance_filter: 'Open Advance Filter',
    },
    visa: {
        enquire_now: 'Enquire Now',
        global_visa_services: 'Global Visa Services',
        greece_header: 'Greece Golden Visa Program',
        greece: 'Greece',
        greece_description: 'Obtain Permanent Residency with an Investment of Just €250,000, Enjoy EU Benefits, Low Immigration Costs, and High Cost-Effectiveness.',
        greece_title1: 'High Cost-Effectiveness',
        greece_text1: 'Investment for three generations (applicant, spouse, and children) without requiring the inclusion of parents.',
        greece_title2: 'Low Investment Threshold',
        greece_text2: 'Only €250,000 needed to purchase property and directly obtain Greek permanent residency.',
        greece_title3: 'No Residency Requirements',
        greece_text3: 'No requirement to live in Greece after obtaining permanent residency.',
        greece_title4: 'Simple Application Requirements',
        greece_text4: 'No need for medical examinations.',
        greece_title5: 'Low Property Tax',
        greece_text5: 'Transaction tax is 3%, with an annual property tax rate of 0.1-0.7%, relatively low compared to other European countries.',
        greece_title6: 'Low Investment Risk',
        greece_text6: 'Property market value has nearly halved, economic recovery is ongoing, and property prices have significant room for growth.',
        greece_title7: 'Travel Freedom',
        greece_text7: 'Greek permanent residents can travel visa-free to 26 Schengen countries.',
        spain: 'Spain',
        spain_header: 'Spain Golden Visa Program',
        spain_description: 'Obtain a Golden Visa for the Whole Family Quickly and Securely with Just €500,000, Free Choice of Property, Transparent Listings.',
        spain_title1: 'For Children\'s Future',
        spain_text1: 'Ensure better opportunities for your children.',
        spain_title2: 'For Investment Returns',
        spain_text2: 'Secure profitable returns on your investment.',
        spain_title3: 'One Application, Three Generations',
        spain_text3: 'One application covers three generations.',
        spain_title4: 'Convenient Travel',
        spain_text4: 'Enjoy visa-free travel to 27 European Schengen Area countries.',
        spain_title5: 'For Clients Without Time to Travel Temporarily',
        spain_text5: 'Suitable for clients who are unable to travel frequently.',
        spain_title6: 'Application Requirements',
        spain_text6_1: '1. The applicant must be at least 18 years old.',
        spain_text6_2: '2. The applicant must have no criminal record.',
        spain_text6_3: '3. The applicant must purchase property in Spain worth 500,000 Euros or more (there is no restriction on property type, including apartments, row houses, independent houses, commercial properties, etc.). It is possible to purchase multiple properties as long as the total net value exceeds 500,000 Euros.',
        portugal: 'Portugal',
        portugal_header: 'Portugal Golden Visa Program',
        portugal_description: 'Obtain a Portugal Golden Visa through investment of 500,000 Euros in funds',
        portugal_title1: 'Safe Investment',
        portugal_text1: 'Investment in property is safe and reliable, offering more stable returns.',
        portugal_title2: 'European Identity',
        portugal_text2: 'With direct access to Europe, enjoy visa-free travel to 26 Schengen countries.',
        portugal_title3: 'Residency Status',
        portugal_text3: 'Holders of the Golden Visa can live, work, and conduct business in Portugal.',
        portugal_title4: 'Family Benefits',
        portugal_text4: 'One application can include three generations of the family.',
        portugal_title5: 'Education and Healthcare',
        portugal_text5: 'The holder and their family can enjoy free public education and free public healthcare in Portugal.',
        portugal_title6: 'No Residency Requirement',
        portugal_text6: 'Only 7 days of residence are required each year, and after 5 years, it is easy to convert to permanent residency without the need to relocate.',
        portugal_title7: 'Low Tax Burden',
        portugal_text7: 'Portugal offers no inheritance tax and no global tax.',
        portugal_title8: 'Favorable Living Environment',
        portugal_text8: 'Portugal has a mild climate, plenty of sunshine, low cost of living, a high proficiency of English, and is suitable for long-term residence.',
        portugal_title9: 'Application Requirements',
        portugal_text9: '1. The applicant must be at least 18 years old. 2. The applicant must have no criminal record. 3. The applicant must invest 500,000 Euros in a fund. 4. The applicant must reside in Portugal for at least 14 days within every 2-year period.',
        cyprus: 'Cyprus',
        cyprus_header: 'Cyprus Golden Visa Program',
        cyprus_description: 'Obtain a Portugal Golden Visa through investment of 500,000 Euros in funds',
        cyprus_title1: 'Benefits for Elderly Parents and Children',
        cyprus_text1: 'Inclusive benefits for three generations.',
        cyprus_title2: 'Low Immigration Costs',
        cyprus_text2: 'Minimal overall expenses.',
        cyprus_title3: 'No Continuous Residency Requirement',
        cyprus_text3: 'No need to reside continuously in Cyprus.',
        cyprus_title4: 'Application Requirement',
        cyprus_text4: '1. The main applicant must be at least 18 years old with no criminal record. ' +
            '2. Purchase property in Cyprus with a minimum value of €300,000. ' +
            '3. Annual income must be at least €50,000, plus €15,000 for a spouse, and €10,000 for each additional child. ' +
            '(Example: A family of three applying requires an annual income of €50,000 + €15,000 + €10,000 = €75,000/year; ' +
            'wages, pensions, rent, dividends, shares, and other sources of income are acceptable.)',
        cyprus_title5: 'Accompanying Family Members',
        cyprus_text5: '1. Spouse (including common-law spouse). ' +
            '2. Children: unmarried children under 18 years old, and unmarried children aged 18-25 who are currently studying full-time.',
        uk: 'United Kingdom',
        uk_header: 'UK Innovator Founder Visa',
        uk_description: 'Achieve immigrant status in the UK by establishing an innovative, feasible, and scalable business.',
        uk_title1: 'Quick Pathway to UK Residency',
        uk_text1: 'Fast track to obtaining a UK green card.',
        uk_title2: 'Low Investment Requirement',
        uk_text2: 'A minimum investment of 50,000 GBP.',
        uk_title3: 'No Prior Business Experience Needed',
        uk_text3: 'No previous entrepreneurial experience is required.',
        uk_title4: 'Family Inclusion',
        uk_text4: 'The main applicant can include their spouse and children under 18 years old in the application.',
        uk_title5: 'Permanent Residency',
        uk_text5: 'The main applicant can apply for permanent residency after 3 years, and the spouse and children can apply after 5 years.',
        uk_title6: 'Application Requirement',
        uk_text6_1: '1. Provide an IELTS score of 5.5 or above. If the applicant has a bachelor\'s degree or higher from a recognized English-taught program in the UK, IELTS may be waived.',
        uk_text6_2: '2. Have at least 50,000 GBP available for investment.',
        uk_text6_3: '3. After approval, the business established in the UK must demonstrate innovation, viability, and scalability.',
        uk_text6_4: '4. Pass a health examination with no serious medical conditions.',
        uk_title7: 'Accompanying Family Members',
        uk_text7: 'Spouse, civil or unmarried partner, and dependent children aged 18 and under (or over 18 if currently in the UK).',
        uk_title8: 'Processing time',
        uk_text8: 'The average processing time for obtaining a UK Innovator and Founder visa is 3 weeks if the applicant is located outside the UK; the average processing time is 8 weeks if the applicant is located in the UK.',
        italy: 'Italy',
        italy_header: 'Italy Golden Visa Program',
        italy_description: 'Offers non-EU investors Italian residency through several investment options, with benefits such as the right to live, work, and study in the EU.',
        italy_title1: 'No language test required',
        italy_title2: 'No minimum stay in Italy',
        italy_title3: 'Live, work and study in Italy.',
        italy_title4: 'Live in Italy and enjoy the benefits of a country ranked number one for climate, heritage and food.',
        italy_title5: 'Access to one of the best education and healthcare systems in the world.',
        italy_title6: 'Tax exemption on gifts, inheritances and profits outside Italy.',
        italy_title7: 'Holders enjoy the freedom to travel within the Schengen Zone.',
        italy_title8: 'Several Investment Forms.',
        italy_text8_1: 'A minimum of €2 million in government bonds.',
        italy_text8_2: 'At least €500,000 in corporate bonds or shares.',
        italy_text8_3: 'A minimum of €250,000 in innovative startups.',
        italy_title9: 'Application Requirements',
        italy_text9_1: 'The applicant must be at least 18 years old.',
        italy_text9_2: 'The applicant must have no criminal record.',
        italy_text9_3: 'The applicant must hold a valid health insurance that covers all risks in Italy.',
        italy_text9_4: 'The applicant must provide proof of the legal source of the invested funds.',
        italy_text9_5: 'The applicant must comply with the minimum stay requirements to maintain and renew the residency permit.',
        italy_title10: 'Accompanying Family Members',
        italy_text10_1: 'Legally married spouse.',
        italy_text10_2: 'Minor children under 18 years old or dependent adult children who are students and are not married.',
        italy_text10_3: 'Parents who are financially dependent on the investor can be included if they meet specific age and dependency requirements.',
    },
    citizenship: {
        citizenship_investment: 'Citizenship by Investment',
        list_of_countries: 'List of Countries',
        citizenship_investment_text: 'The Citizenship by Investment Programme offers foreign individuals and their families the opportunity to obtain citizenship through a certificate of naturalization by direct investment.',
        dominica: 'Dominica',
        north_macedonia: 'North Macedonia',
        grenada: 'Grenada',
        dominica_description: 'The Dominica Citizenship by Investment Program was launched in 1993 by the Government of the Commonwealth of Dominica and allows foreign investors to obtain irrevocable citizenship without residency requirements or the need to renounce their current nationality.',
        dominica_title1: 'Processing time',
        dominica_text1: 'The average processing time for obtaining Dominica citizenship is 4 months.',
        dominica_title2: 'Visa-free countries',
        dominica_text2: 'Instant visa-free travel to 144 countries/regions, including Schengen countries, Singapore, Hong Kong and China.',
        dominica_title3: 'Investment type',
        dominica_text3: 'Donation to Dominica government funds or real estate investment.',
        dominica_title4: 'Investment cost',
        dominica_text4: ' US$100,000 for a single applicant and US$175,000 for a family of four.',
        dominica_title5: 'Family dependents',
        dominica_text5: 'Spouse, children under 30 years old, parents/grandparents over 65 years old.',
        dominica_title6: 'Passport validity',
        dominica_text6: 'For people over 16, it is valid for 10 years. For children under 16, it is valid for five years.',
        dominica_title7: 'Good identity',
        dominica_text7: 'Dominica allows dual citizenship without any restrictions. There is no need to renounce your current citizenship.',
        dominica_title8: 'Family Benefits',
        dominica_text8_1: 'You and your family can hold irrevocable citizenship for life. In addition, future generations can also benefit from citizenship by descent.',
        dominica_text8_2: 'In addition to the main applicant, spouse, children under 30 years old, and parents/grandparents over 65 years old can also obtain a Dominica passport.',
        dominica_title9: 'International Travel Benefits',
        dominica_text9_1: 'The Dominican Republic\'s prestigious passport provides visa-free access to over 144 countries, including the UK and the Schengen Area.',
        dominica_text9_2: 'Residency Rights Dominican passports offer extended residency in the UK for up to 6 months and an easy visa application process.',
        dominica_text9_3: 'Embrace a life of freedom and the right to reside in the Dominican Republic and many other CARICOM member countries for as long as you wish.',
        dominica_title10: 'Tax and financial benefits',
        dominica_text10_1: 'There are no capital gains, gift, wealth and inheritance taxes; non-residents pay no tax.',
        dominica_text10_2: 'You can open bank accounts with reputable financial institutions around the world.',
        dominica_text10_3: 'Duty-free trade in the Caribbean.',
        dominica_text10_4: 'There are no restrictions on the repatriation of profits and imported capital.',
        enquire_now: 'Enquire Now',
        north_macedonia_description: 'The North Macedonia Citizenship by Investment Program enables foreign individuals to invest in the country and seek alternative citizenship by investing in a government-approved fund. North Macedonia has one of the most attractive and business-friendly citizenship investment programs in Europe. However, it is important to note that only a limited number of individuals, specifically 1,000 applicants worldwide, will be accepted into the program. North Macedonia is a NATO member, EU member, and a fully democratic republic in the heart of Europe.',
        north_macedonia_title1: 'Processing time',
        north_macedonia_text1: 'The average processing time for obtaining North Macedonian citizenship is 4 months.',
        north_macedonia_title2: 'Visa-free countries',
        north_macedonia_text2: 'Instant visa-free travel to 125 countries, including Schengen countries, China and Japan.',
        north_macedonia_title3: 'Investment type',
        north_macedonia_text3: 'Investment in a private investment fund or direct investment in a new facility.',
        north_macedonia_title4: 'Investment cost',
        north_macedonia_text4: 'From 200,000 EURO.',
        north_macedonia_title5: 'Family dependents',
        north_macedonia_text5: 'Spouse and dependent children under 18 years of age (spouse will be granted citizenship one year after the principal applicant and dependent children).',
        north_macedonia_title6: 'Passport validity',
        north_macedonia_text6: 'North Macedonian passports are valid for ten (10) years.',
        north_macedonia_title7: 'Fast and easy',
        north_macedonia_text7: 'There are no language tests, experience, or minimum stay requirements.',
        north_macedonia_title8: 'Freedom and EU citizenship',
        north_macedonia_text8_1: 'Visa-free travel to over 125 countries including the EU, Schengen, China, and Japan.',
        north_macedonia_text8_2: 'Candidate for EU membership.',
        north_macedonia_text8_3: 'Dual citizenship is allowed.',
        north_macedonia_title9: 'Wealth Protection',
        north_macedonia_text9_1: 'Fixed currency is Euro.',
        north_macedonia_text9_2: 'Zero tax for non-residents.',
        north_macedonia_text9_3: '100% company ownership, zero tax in manufacturing free zones.',
        grenada_description: 'Launched in August 2013, the Grenada Citizenship by Investment Program offers investors numerous benefits, including visa-free access to Schengen countries, the United Kingdom, and China, as well as being the only Caribbean country with a CBI program and a treaty with the United States, allowing Grenadian passport holders to apply for an E-2 US "Investor Visa" to reside in the U.S., without the need to reside in Grenada or renounce their current citizenship to obtain irrevocable citizenship and passports.',
        grenada_title1: 'Processing time',
        grenada_text1: 'The average processing time for obtaining Grenada citizenship is 5 months.',
        grenada_title2: 'Visa-free countries',
        grenada_text2: 'Instant visa-free travel to 145 countries, including Schengen countries, China, UK, Singapore, and Hong Kong.',
        grenada_title3: 'Investment type',
        grenada_text3: 'Donate to the Grenada government or invest in real estate projects approved by the Grenada government.',
        grenada_title4: 'Investment cost',
        grenada_text4: '$150,000.',
        grenada_title5: 'Family dependents',
        grenada_text5: 'Spouse, children under 30 years old, elderly parents over 55 years old, brothers and sisters over 18 years old.',
        grenada_title6: 'Passport validity',
        grenada_text6: 'Grenadian passports are valid for five (5) years.',
        grenada_title7: 'Good identity',
        grenada_text7_1: 'Grenada Citizenship by Investment offers you irrevocable citizenship for life.',
        grenada_text7_2: 'Descendants can also benefit from citizenship by descent.',
        grenada_text7_3: 'Grenada allows dual citizenship without any restrictions, so you can keep your current citizenship without any limitations.',
        grenada_text7_4: 'In addition to the main applicant, the spouse, children under 30 years of age, elderly parents over 55 years of age, and siblings aged 18 years and above can also benefit from Grenadian citizenship.',
        grenada_title8: 'A passport in good standing',
        grenada_text8_1: 'Grenadian passport holders can travel visa-free to 145 countries, including China, Schengen countries, the United Kingdom, Singapore, Hong Kong, and Australia.',
        grenada_text8_2: 'Biometric e-passports for enhanced security and anti-counterfeiting measures.',
        grenada_text8_3: 'The Treaty of Commerce and Navigation with the United States allows citizens of Grenada to apply for an E-2 visa, which gives them the right to reside in the United States.',
        grenada_title9: 'Tax incentives',
        grenada_text9_1: 'Grenadian passport holders enjoy tax exemptions on capital gains, gifts, wealth, and inheritance, while non-residents are not subject to tax.',
        grenada_text9_2: 'Grenadian citizenship offers attractive benefits such as corporate tax incentives, complete exemption from import duties, tax relief incentives, and export subsidies.',
    },
    home: {
        singapore_top_viewed: 'Top Viewed Singapore Properties',
        singapore_top_viewed_label: 'Discover the most sought-after properties in Singapore. Explore prime locations, luxurious amenities, and exclusive residences that define urban living in the Lion City.',
        contact_us: 'Contact Us',
        home_banner_citizenship: 'Citizenship by Investment',
        home_banner_successful_apps: 'Successful Applications',
        home_banner_positive_reviews: 'Positive Reviews',
        home_banner_global_visa: 'Global Visa',
        featured_property: 'Featured Property Types',
        featured_property_title: 'Find All Type of Property',
        family_house: 'Family House',
        all_property: 'Find All Type of Property',
        property: 'Property',
        house_villa: 'House & Villa',
        apartment: 'Apartment',
        office_studio: 'Office & Studio',
        villa_condo: 'Villa & Condo',
        greece_featured: 'Greece Featured Listed Property',
        greece_featured_text: 'Discover the epitome of Mediterranean luxury with this stunning villa located on the pristine coast of Greece.',
        beds: 'Beds',
        bath: 'Bath',
        malaysia_featured: 'Malaysia Listed Sale Property',
        malaysia_featured_text: 'Experience the perfect blend of modern luxury and cultural richness with this exquisite property in Malaysia.',
        citizenship_investment: 'Citizenship by Investment',
        citizenship_investment_text: 'We provide expert assistance for all citizenship by investment applications worldwide, providing a customized service experience supported and sustained by long-term partnerships.',
        listings: 'Listings',
        dominica: 'Dominica',
        dominica_title: 'Dominica Citizenship by Investment',
        dominica_description: 'Obtain Citizenship with an Investment of Just US$100,000, Enjoy Instant visa-free travel to 144 countries, Hold irrevocable citizenship for life.',
        find_out_more: 'Find out more',
        north_macedonia: 'North Macedonia',
        north_macedonia_title: 'North Macedonia Citizenship by Investment Program',
        north_macedonia_description: 'Obtain a North Macedonia Citizenship fast and easy starting with €200,000, Dual citizenship is allowed, Wealth Protection',
        grenada: 'Grenada',
        grenada_title: 'Grenada Citizenship by Investment Program',
        grenada_description: 'Obtain a Grenada Citizenship for the Whole Family Quickly and Securely in Europe, Enjoy tax exemptions on capital gains, gifts, wealth and inheritance',
        top_property_places: 'Top Property Places',
        top_property_description: 'Explore seamless and secure home transactions globally, with top properties available in Greece, Malaysia and Singapore',
        greece: 'Greece',
        malaysia: 'Malaysia',
        singapore: 'Singapore',
        properties: 'Properties',
        global_visa_services: 'Global Visa Services',
        global_visa_text: 'We provide expert assistance for all types of visa applications worldwide, ensuring a smooth and hassle-free process tailored to your specific needs.',
        greece_visa_text: 'Greece Golden Visa Program',
        greece_visa_description: 'Obtain Permanent Residency with an Investment of Just €250,000, Enjoy EU Benefits, Low Immigration Costs, and High Cost-Effectiveness',
        spain_visa_text: 'Spain Golden Visa Program',
        spain_visa_description: 'Obtain a Golden Visa for the Whole Family Quickly and Securely with Just €500,000, Free Choice of Property, Transparent Listings',
        portugal_visa_text: 'Portugal Golden Visa Program',
        portugal_visa_description: 'Obtain a Golden Visa for the Whole Family Quickly and Securely in Europe\'s Financial Center, with Transparent Property Choices',
        uk_visa_text: 'UK Innovator Founder Visa',
        uk_visa_description: 'Achieve immigrant status in the UK by establishing an innovative, feasible, and scalable business with minimum investment of £50,000.',
        italy_visa_text: 'Italy Golden Visa Program',
        italy_visa_description: 'Offers non-EU investors Italian residency through several investment options, enjoy multiple benefits such as the right to live, work, and study in the EU.',
        us_visa_text: 'U.S. E-2 Investor Residence Visa',
        us_visa_description: 'U.S. E-2 Investor Residence Visa is a non-immigrant visa, allows a foreign investor and his or her family to enter and work in the United States.',
        cyprus_visa_text: 'Cyprus Golden Visa Program',
        cyprus_visa_description: 'Obtain Permanent Residency for the Whole Family with Just €300,000, No Continuous Residency Requirement, Benefits for Elderly Parents and Children',
        portugal: 'Portugal',
        uk: 'United Kingdom',
        spain: 'Spain',
        italy: 'Italy',
        us: 'United States',
        cyprus: 'Cyprus',
    },
    property: {
        submit_enquiry: 'Submit Enquiry',
        reviews: 'Reviews',
        buy_new_property: 'Buy New Property',
        buy_second_hand_property: 'Buy Second-Hand Property',
        rent_property: 'Rent Property',
        title: 'Title',
        property: 'Property',
        about_property: 'About Property',
        property_information: 'Property Information',
        open_to_foreigner: 'Open to Foreigners',
        tenure: 'Tenure',
        units: 'Units',
        towers: 'Towers',
        completed_in: 'Completed in',
        furnished: 'Furnished',
        free_car_park: 'Free Car Park Available',
        tite: 'Title',
        airbnb_management: 'Airbnb Management',
        facilities: 'Facilities',
        unit_layout: 'Unit Layout',
        type: 'Type',
        beds: 'Beds',
        baths: 'Baths',
        area: 'Area',
        property_video: 'Property Video',
        location: 'Location',
        bikeable_score: 'Bikeable Score',
        some_bike_infrastructure: 'Some Bike Infrastructure',
        view_detail: 'View Detail',
        walk_scores: 'WalkScore',
        most_errands_accomplished_on_foot: 'Most errands can be accomplished on foot',
        some_transit: 'Some Transit',
        nearby_public_transport_options: 'A few nearby public transportation options',
        nearby: 'Nearby',
        schools: 'Schools',
        walkscore: 'WalkScore',
        hotel_restaurant: 'Hotel & Restaurant',
        health_medical: 'Health & Medical',
        want_to_know_more: 'Want to Know More',
        similar_property: 'Similar Property',
        sale: 'Sale',
        rent: 'Rent',
        sale_price: 'Sale Price',
        down_payment: 'Down Payment',
        loan_term: 'Loan Term',
        interest_rate: 'Interest Rate (%)',
        mortgage_calculator: 'Mortgage Calculator',
        monthly_payment: 'Monthly Payment',
        calculate: 'Calculate',
        for: 'For',
    },
    cta: {
        do_you_have_questions: 'Do You Have Questions ?',
        help_to_find_home: 'We will help you to find your next home.',
        contact_us_now: 'Contact Us Now',
    },
    footer: {
        footer: 'Real Estate.',
        hotline: 'Hotline',
        greece: 'Greece',
        singapore: 'Singapore',
        malaysia: 'Malaysia',
        indonesia: 'Indonesia',
        japan: 'Japan',
        germany: 'Germany',
        need_help: 'Do yu need help with anything',
        offices: 'Offices',
        email_address: 'Email Address',
        subscribe: 'Subscribe',
        services: 'Services',
        buy_property: 'Buy Property',
        sell_property: 'Sell Property',
        property_for_rent: 'Property For Rent',
        global_partners: 'Global Partners',
        study_overseas: 'Study Overseas',
        global_visa: 'Global Visa',
        company: 'Company',
        about: 'About',
        countries: 'Countries',
        property_listings: 'Property Listing',
        blog_news: 'Blog or News',
        our_team: 'Our Team',
        contact_us: 'Contact Us',
        email: 'Email',
    },
    property_submission:{
        advertise_your_property: 'Advertise Your Property',
        submit_your_property: 'Submit Your Property',
        property_listing_countries: 'Property Listing Countries',
        property_countries_text: 'Our platform empowers property owners to sell their real estate globally, offering unparalleled exposure to international clients and investors, ensuring your property reaches a worldwide audience eager for investment opportunities.',
        malaysia: 'Malaysia',
        singapore: 'Singapore',
        greece: 'Greece',
        basic_information: 'Basic Information',
        property_title: 'Property Title',
        property_type: 'Property Type',
        property_category: 'Property Category',
    },
    modals: {
        scan_qr_to_connect: 'Scan QR to Connect',
        dream_home_anywhere: 'Your Dream Home, Anywhere In The World',
        login: 'Login',
        register: 'Register',
        username: 'Username',
        password: 'Password',
        remember_me: 'Remember Me',
        lost_password: 'Forgot Password?',
        forgot_password: 'Forgot Password',
        send_reset_email: 'Send Reset Email',
        enter_email_address: 'Enter your email address',
        full_name: 'Full Name',
        email: 'Email',
        phone_number: 'Phone Number',
        terms_conditions: 'By using the website, you acept the terms and conditions.',
        enter_phone_number: 'Enter phone number',
        enter_email: 'Enter email',
        enter_full_name: 'Enter full name',
        enter_password: 'Enter password',
        enter_username: 'Enter username',
    },
    form: {
        name: 'Name',
        phone_number: 'Phone Number',
        messages: 'Messages',
        get_latest_offer: 'Get Latest Offer',
    },
    settings: {
        language: 'Language',
        currency: 'Currency',
        measurement: 'Measurement',
        confirm: 'Confirm',
    },
};
